import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import TicketForm from "../TicketForm/TicketForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ListView.css";
import { FaUser, FaEnvelope } from "react-icons/fa";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdWatchLater } from "react-icons/md";
import Loader from "../../Loader/Loader";
import axios from "axios";

const DROPDOWN_OPTIONS = {
  priorities: ["Low", "Medium", "High", "Urgent"],
  dueStatuses: ["Open", "Pending", "Resolved", "Closed"],
};

const ListView = ({
  isLoading,
  setIsLoading,
  defaultTickets,
  setDefaultTickets,
  hasFetchedAgentTickets,
  setHasFetchedAgentTickets,
  hasFetchedSuperTickets,
  setHasFetchedSuperTickets,
  fetchSuperAdminLists,
  tickets,
  setTickets,
}) => {
  const navigate = useNavigate();

  // console.log("TiketsList",ticketsData)

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState([]);
  const [dueStatusDropdownOpen, setDueStatusDropdownOpen] = useState(null);
  const [selectedPriorities, setSelectedPriorities] = useState({});
  const [selectedDueStatuses, setSelectedDueStatuses] = useState([]);
  const [item, setItem] = useState(null);
  const [agents, setAgents] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const [selectedTicket, setselectedTicket] = useState(null); // Initially set ticket_id as 2
  const [agentTicket, setAgentData] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState({});
  const [ticketStatuses, setTicketStatuses] = useState({});

  const dropdownRefs = useRef([]);
  // const [current_status_new, setCurrent_status_new] = useState([
  //   "Open",
  //   "Pending",
  //   "Resolved",
  //   "Closed",
  // ]);
  // const [current_priority_new, setCurrent_priority_new] = useState([
  //   "Low",
  //   "Medium",
  //   "High",
  //   "Urgent",
  // ]);

  // console.log("my prio",current_priority_new)

  useEffect(() => {
    const storedItem = sessionStorage.getItem("TicketSystemSuperRoleType");
    console.log(storedItem, "ttttttttttttttttttttttttttttttt");
    if (storedItem) {
      setItem(storedItem);
    }
  }, []);

  const dropdownRef = useRef(null);
  const dueStatusDropdownRef = useRef(null);

  const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

  const getPriorityClass = (priority) => {
    const priorityClasses = {
      Low: "priority-low",
      Medium: "priority-medium",
      High: "priority-high",
      Urgent: "priority-urgent",
    };
    return priorityClasses[priority] || "";
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      dropdownRefs.current.forEach((dropdown, index) => {
        if (dropdown && !dropdown.contains(event.target)) {
          setDropdownOpen((prevState) => {
            const updatedDropdownOpen = [...prevState];
            updatedDropdownOpen[index] = false; // Close dropdown for this index
            return updatedDropdownOpen;
          });
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Toggle dropdown open state for the clicked index
  const handlePriorityClick = (index) => {
    setDropdownOpen((prevState) => {
      const updatedDropdownOpen = [...prevState];
      updatedDropdownOpen[index] = !updatedDropdownOpen[index]; // Toggle the dropdown state
      return updatedDropdownOpen;
    });
  };

  // Handle the selection of a priority
  const handleSelectPriority = async (priority, index, ticketId) => {
    // Update the selected priority
    setSelectedPriorities((prev) => {
      const updatedPriorities = [...prev];
      updatedPriorities[index] = priority;
      return updatedPriorities;
    });

    await updatePriority(ticketId, priority);

    setDropdownOpen((prev) => {
      const updatedDropdownState = [...prev];
      updatedDropdownState[index] = false; // Close dropdown for this index
      return updatedDropdownState;
    });
  };

  useEffect(() => {
    fetchSuperAdminLists();
    return () => {};
  }, [token, item, hasFetchedAgentTickets]);

  const fetchAgents = async () => {
    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/agents/getAllAgents_new",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log("Fetched agents data:", data.data); // Log the data
      if (data && data.data && Array.isArray(data.data)) {
        setAgents(data?.data); // Set agents to the fetched array
      } else {
        toast.error("Failed to retrieve agents");
        setAgents([]); // Set to empty array if the response is not valid
      }
    } catch (error) {
      toast.error("An error occurred while fetching agents");
      setAgents([]); // Set to empty array on error
    }
  };
  useEffect(() => {
    fetchAgents();
  }, [token]);

  useEffect(() => {
    const fetchFilteredData = () => {
      const filteredData = sessionStorage.getItem("filteredData");
      if (filteredData) {
        // setTickets(JSON.parse(filteredData));
      }
    };

    const interval = setInterval(() => {
      fetchFilteredData();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleAgentChange = async (e, ticketssssssss) => {
    const agentName = e.target.value;
    // setselectedTicket(ticketssssssss);
    // setSelectedAgentId(agentName);

    setSelectedAgents((prev) => ({
      ...prev,
      [ticketssssssss.id]: agentName,
    }));
    if (agentName) {
      const agent = agents.find((agent) => agent.agent_name === agentName);

      if (agent) {
        await assignTicketToAgent(agent?.agent_id, ticketssssssss?.id);
      }
    }
  };

  const assignTicketToAgent = async (agentId, ticketId, fetchAgents) => {
    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/assign_ticket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ticket_id: ticketId,
            agent_id: agentId,
          }),
        }
      );

      const result = await response.json();

      if (response.ok && result.response === true) {
        toast.success("Agent updated successfully!");
        fetchAgents();
      } else {
        toast.error("Failed to assign ticket.");
      }
    } catch (error) {
      console.error("Error assigning ticket:", error);
    }
  };

  const updateTicketStatus = async (ticketId, status) => {
    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/update_ticket_status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Ensure `token` is defined in your code
          },
          body: JSON.stringify({
            ticket_id: ticketId,
            ticket_status: status,
          }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        toast.success("Ticket status updated successfully!");
        console.log("Ticket status updated successfully:", result);
      } else {
        toast.error(`Failed to update ticket status: ${result.message || ""}`);
        console.error("Failed to update ticket status:", result);
      }
    } catch (error) {
      toast.error("Error updating ticket status. Please try again.");
      console.error("Error updating ticket status:", error);
    }
  };

  const handleDueStatusClick = (index) => {
    // Toggle the dropdown open/close
    setDueStatusDropdownOpen(dueStatusDropdownOpen === index ? null : index);
  };

  // Handle status selection and pass ticket ID to the update function
  const handleSelectDueStatus = async (status, index, ticketId) => {
    // Update the selected due status
    setSelectedDueStatuses((prevStatuses) => {
      const updatedStatuses = [...prevStatuses];
      updatedStatuses[index] = status;
      return updatedStatuses;
    });

    // Set the selected ticket
    setselectedTicket(ticketId);

    // Close the dropdown
    setDueStatusDropdownOpen(null);

    // Perform the async operation to update the ticket status
    await updateTicketStatus(ticketId, status);
  };

  // const handleSelectDueStatus_New = async (ticketId, status) => {
  //   await updateTicketStatus(ticketId, status);
  // };
  // const handleSelectDueStatus_New = async (ticketId, status) => {
  //   // Perform the async operation to update the ticket status
  //   await updateTicketStatus(ticketId, status);

  //   // Update the local state for ticket statuses
  //   setTicketStatuses((prevStatuses) => ({
  //     ...prevStatuses,
  //     [ticketId]: status,
  //   }));
  // };

  const handleSelectDueStatus_New = async (ticketId, status) => {
    await updateTicketStatus(ticketId, status);
    setTicketStatuses((prevStatuses) => {
      const updatedStatuses = { ...prevStatuses, [ticketId]: status };
      sessionStorage.setItem('ticketStatuses', JSON.stringify(updatedStatuses));
      return updatedStatuses;
    });
  };

  // Load ticket statuses from sessionStorage on initial render
  useEffect(() => {
    const savedStatuses = sessionStorage.getItem('ticketStatuses');
    if (savedStatuses) {
      setTicketStatuses(JSON.parse(savedStatuses));
    }
  }, []);
  const handleSelectPriority_New = async (ticketId, priority) => {
    // Perform the async operation to update the ticket status
    await updatePriority(ticketId, priority);
  };

  useEffect(() => {
    if (defaultTickets.length > 0) {
      setTickets(defaultTickets);
      setSelectedPriorities(defaultTickets.map((ticket) => ticket.priority));
      setSelectedDueStatuses(defaultTickets.map((ticket) => ticket.dueStatus));
    }
  }, [defaultTickets]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(null);
      }
      if (
        dueStatusDropdownRef.current &&
        !dueStatusDropdownRef.current.contains(event.target)
      ) {
        setDueStatusDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addNewTicket = (newTicket) => {
    setDefaultTickets((prevTickets) => [newTicket, ...prevTickets]);
  };

  const handleTicketClick = (ticket) => {
    // Navigate to the ticket scenario page with the ticket id
    navigate(`/ticket-scenario/${ticket.id}`);
    // Optionally set additional details in state
    setselectedTicket(ticket);
  };

  const updatePriority = async (ticketId, priority) => {
    try {
      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/users/update_priority_status",
        {
          ticket_id: ticketId,
          ticket_priority: priority,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Assuming token is passed this way
          },
        }
      );

      console.log(response.data);

      // Optionally update ticket data in your state
      setDefaultTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket.id === ticketId ? { ...ticket, priority } : ticket
        )
      );

      // Show a success toast
      toast.success("Priority updated successfully!");
    } catch (error) {
      console.error("Error updating priority:", error);
      toast.error("Failed to update priority."); // Optional error message
    }
  };

  return (
    <div className="container container-listview-main">
      <ToastContainer />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isFormVisible && (
            <TicketForm
              onClose={() => setIsFormVisible(false)}
              addNewTicket={addNewTicket}
            />
          )}

          {tickets.length > 0 ? (
            tickets.map((ticketssssssss, index) => (
              <div
                key={index}
                className={`ticket-card p-3 mb-3 mt-3 border ${
                  ticketStatuses[ticketssssssss.id] === "Closed"
                    ? "disabled-card"
                    : ""
                }`}
              >
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div className="Status-badge-ListView">
                    <strong className="ticket-title">
                      {ticketStatuses[ticketssssssss.id] === "Closed" ? (
                        <span style={{ color: "gray", cursor: "not-allowed" }}>
                          {ticketssssssss.title} {ticketssssssss.id}
                        </span>
                      ) : (
                        <Link
                          to={`/ticket-scenario/${ticketssssssss.id}/${ticketssssssss.creator}`}
                          style={{
                            textDecoration: "none",
                            color: "rgb(18, 52, 77)",
                          }}
                          onClick={() => handleTicketClick(ticketssssssss)}
                        >
                          {ticketssssssss.id}. {ticketssssssss.title}
                        </Link>
                      )}
                    </strong>
                  </div>

                  {/* PRIORITY  */}
                  <select
                    name="agent"
                    className="agent-dropdown"
                    onChange={(e) => {
                      handleSelectPriority_New(
                        ticketssssssss?.id,
                        e.target.value
                      );
                    }}
                    style={
                      ticketStatuses[ticketssssssss.id] === "Closed"
                        ? { color: "gray", cursor: "not-allowed" }
                        : {}
                    }
                    disabled={ticketStatuses[ticketssssssss.id] === "Closed"}
                  >
                    <option value="">Select...</option>
                    {DROPDOWN_OPTIONS.priorities.map((priority) => (
                      <option
                        key={priority}
                        value={priority}
                        selected={ticketssssssss?.priority === priority}
                      >
                        {priority}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className={`ticket-details ${
                    ticketStatuses[ticketssssssss.id] === "Closed"
                      ? "disabled-content"
                      : ""
                  }`}
                >
                  <div className="d-flex align-items-center">
                    <FaUser className="mr-1" />
                    <span>{ticketssssssss.creator}</span>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <div
                      style={{
                        flexShrink: 0,
                        width: "20px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <FaEnvelope className="mr-1" />
                    </div>
                    <span
                      className="message-mail-list"
                      dangerouslySetInnerHTML={{
                        __html: ticketssssssss.message,
                      }}
                    ></span>
                  </div>

                  <div className="d-flex align-items-center mt-2">
                    <MdWatchLater className="mr-1 watch-icon" />
                    {/* Created: */} {ticketssssssss.created}
                    {/* <span>Created: {ticketssssssss.created}</span> */}
                  </div>

                  {/* <div className="text-muted">
                  First response due: {ticketssssssss.firstResponseDue}
                </div> */}
                  {ticketssssssss.due && (
                    <div className="text-muted">Due: {ticketssssssss.due}</div>
                  )}
                </div>

                {/* AGENT ASSIGN  */}
                <div className="d-flex justify-content-between align-items-center mt-2">
                  <div
                    style={{
                      visibility: item === "agent" ? "hidden" : "visible",
                    }}
                  >
                    <label>Agent :</label>
                    <select
                      name="agent"
                      className="agent-dropdown"
                      value={
                        selectedAgents[ticketssssssss?.id] ||
                        ticketssssssss?.agentNameByAPI
                      }
                      onChange={(e) => {
                        handleAgentChange(e, ticketssssssss);
                      }}
                    >
                      <option value="">Select...</option>
                      {agents.map((agent) => (
                        <option key={agent.agent_id} value={agent.agent_name}>
                          {agent.agent_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* status */}
                  <div className="d-flex align-items-center">
                    <select
                      name="agent"
                      className="agent-dropdown"
                      onChange={(e) => {
                        handleSelectDueStatus_New(
                          ticketssssssss.id,
                          e.target.value
                        );
                      }}
                    >
                      <option value="">Select...</option>
                      {DROPDOWN_OPTIONS.dueStatuses.map((status) => (
                        <option
                          key={status}
                          value={status}
                          selected={ticketssssssss?.dueStatus == status ? true : false}
                        >
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center mt-3">
              <p>No ticket available</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ListView;
