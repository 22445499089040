import React, { useRef, useState, useEffect } from "react";
import "./Forward.css";
import {
  BiBold,
  BiItalic,
  BiUnderline,
  BiAlignLeft,
  BiAlignMiddle,
  BiAlignRight,
  BiImage,
  BiRedo,
  BiUndo,
} from "react-icons/bi";
import Select from "react-select";
import Mail_fromListViewForward from "../Mail_fromListView/Mail_fromListViewForward";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Loader/Loader";
import axios from "axios";

const CC_OPTIONS = [
  { value: "akansha@techflux.in", label: "akansha@techflux.in" },
  { value: "radhesha@techflux.in", label: "radhesha@techflux.in" },
  { value: "bhagyashri@techflux.in", label: "bhagyashri@techflux.in" },
];

const BCC_OPTIONS = [
  { value: "akansha@techflux.in", label: "akansha@techflux.in" },
  { value: "radhesha@techflux.in", label: "radhesha@techflux.in" },
  { value: "bhagyashri@techflux.in", label: "bhagyashri@techflux.in" },
];

const Forward = ({ ticketId }) => {
  const editorRef = useRef(null);
  const [selectionRange, setSelectionRange] = useState(null);
  const [ccOptions, setCcOptions] = useState([]);
  const [bccOptions, setBccOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [agentEmails, setAgentEmails] = useState([]);
  const [ticketMailTo, setTicketMailTo] = useState(""); // Default to an empty string

  useEffect(() => {
    const fetchAgentEmails = async () => {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
      try {
        setLoading(true);
        const response = await axios.get(
          "https://ticketsystem.techfluxsolutions.com/agents/getAllAgentsWithSuperadmin",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Extract agent emails from the response
        const emails = response.data.data.map((agent) => ({
          value: agent.agent_email,
          label: agent.agent_email,
        }));

        // Update the state with the fetched emails
        setAgentEmails(emails);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to fetch agent emails.");
      }
    };

    fetchAgentEmails();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSelectionRange(selection.getRangeAt(0));
    }
  };

  const restoreSelection = () => {
    if (selectionRange) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(selectionRange);
    }
  };

  const handleCommand = (command, value = null) => {
    restoreSelection();
    document.execCommand(command, false, value);
  };

  // const handleSend = async () => {
  //   setLoading(true);
  //   const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
  //   const messageContent = editorRef.current.innerHTML;
  //   const ticketForwardCc = ccOptions.map((option) => option.value).join(",");
  //   const ticketForwardBcc = bccOptions.map((option) => option.value).join(",");

  //   try {
  //     // Create FormData instance
  //     const formData = new FormData();
  //     formData.append("ticket_id", ticketId); // Replace with dynamic ticket_id if available
  //     formData.append("ticket_forward_cc", ticketForwardCc);
  //     formData.append("ticket_forward_bcc", ticketForwardBcc);
  //     formData.append("ticket_forward_message", messageContent);
  //     formData.append("ticket_forward_to", ticketMailTo);

  //     // Append the attachment if it exists
  //     if (attachment) {
  //       formData.append("attachments", attachment);
  //     }

  //     // Make the POST request
  //     const response = await fetch(
  //       "https://ticketsystem.techfluxsolutions.com/agents/agentTicketForward_attachment",
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`, // Only set Authorization in headers; FormData handles Content-Type
  //         },
  //         body: formData, // Attach FormData
  //       }
  //     );

  //     if (response.ok) {
  //       toast.success("Ticket forwarded successfully!");
  //       fetchForwardData();

  //       // Clear input fields and editor content after successful reply
  //       setCcOptions([]);
  //       setBccOptions([]);
  //       setAttachment(null); // Clear the attachment state after sending
  //       setTicketMailTo("");
  //       editorRef.current.innerHTML = "";
  //     } else {
  //       throw new Error("Failed to forward ticket.");
  //     }
  //   } catch (error) {
  //     toast.error(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const handleSend = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
    const messageContent = editorRef.current.innerHTML;
    const ticketForwardCc = ccOptions.map((option) => option.value).join(",");
    const ticketForwardBcc = bccOptions.map((option) => option.value).join(",");
  
    try {
      // Create FormData instance
      const formData = new FormData();
      formData.append("ticket_id", ticketId); // Replace with dynamic ticket_id if available
      formData.append("ticket_forward_cc", ticketForwardCc);
      formData.append("ticket_forward_bcc", ticketForwardBcc);
      formData.append("ticket_forward_message", messageContent);
      formData.append("ticket_forward_to", ticketMailTo);
  
      // Append the attachment if it exists
      if (attachment) {
        formData.append("attachments", attachment);
      }
  
      // Make the POST request
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/agents/agentTicketForward_attachment",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`, // Only set Authorization in headers; FormData handles Content-Type
          },
          body: formData, // Attach FormData
        }
      );
  
      if (response.ok) {
        toast.success("Ticket forwarded successfully!");
        fetchForwardData();
  
        // Clear input fields, editor content, and attachment after successful reply
        setCcOptions([]);
        setBccOptions([]);
        setAttachment(null); // Clear the attachment state
        setTicketMailTo("");
        editorRef.current.innerHTML = "";
  
        // Clear the file input field
        const fileInput = document.getElementById("image-upload");
        if (fileInput) {
          fileInput.value = "";
        }
      } else {
        throw new Error("Failed to forward ticket.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const [threadData, setThreadData] = useState([]);
  const [error, setError] = useState(null);

  // const fetchForwardData = async () => {
  //   try {
  //     const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

  //     if (!token) {
  //       throw new Error("No authentication token found in session storage.");
  //     }
  //     setLoading(true);

  //     const response = await axios.get(
  //       `https://ticketsystem.techfluxsolutions.com/agents/getForwardByTicketId/${ticketId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setLoading(false);

  //     console.log("API Response:", response.data); // Log the response data

  //     // Extract the forward data and format it
  //     const forward = response.data.data.forward;
  //     const formattedReplies = Object.values(forward).map((forwardItem) => ({
  //       ticket_forward_to: forwardItem.ticket_forward_to,
  //       sender: forwardItem.ticket_forward_cc.join(", "), // Join CC addresses to display
  //       email: forwardItem.ticket_forward_bcc.join(", "), // Join BCC addresses to display
  //       message: forwardItem.ticket_forward_message,
  //       replies: [], // Placeholder for nested replies if needed
  //     }));

  //     // Set the thread data
  //     setThreadData(formattedReplies);
  //   } catch (err) {
  //     // setError(err.message);
  //     toast.warning("There is no conversation..");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchForwardData = async () => {
    try {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      if (!token) {
        throw new Error("No authentication token found in session storage.");
      }
      setLoading(true);

      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/agents/getForwardByTicketId/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);

      console.log("API Response:", response.data); // Log the response data

      // Extract the forward data
      const forward = response.data.data.forward;

      // Extract ticket_forward_to from the first forward item
      const ticketForwardTo = forward["22"]?.ticket_forward_to || ""; // Adjust key based on dynamic data

      // Update the ticketMail state
      setTicketMailTo(ticketForwardTo);

      // Format the replies for threading or display
      const formattedReplies = Object.values(forward).map((forwardItem) => ({
        ticket_forward_to: forwardItem.ticket_forward_to,
        sender: forwardItem.ticket_forward_cc.join(", "), // Join CC addresses to display
        email: forwardItem.ticket_forward_bcc.join(", "), // Join BCC addresses to display
        message: forwardItem.ticket_forward_message,
        replies: [], // Placeholder for nested replies if needed
      }));

      // Set the thread data
      setThreadData(formattedReplies);
    } catch (err) {
      toast.warning("There is no conversation.");
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachment(file); // Save the file for the attachment
      const fileRepresentation = `
        <div contenteditable="false" style="display: inline-block; padding: 5px; margin: 5px; border: 1px solid #ccc; border-radius: 4px; background-color: #f9f9f9;">
          <span style="display: flex; align-items: center;">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill="#555" viewBox="0 0 16 16" style="margin-right: 5px;">
              <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5v11A1.5 1.5 0 0 0 4.5 15h7a1.5 1.5 0 0 0 1.5-1.5v-9l-4-4H4.5zm4 1.5V6h3.5a.5.5 0 0 0-.5-.5H9V2.5H8.5a.5.5 0 0 0 .5.5z"/>
            </svg>
            ${file.name}
          </span>
        </div>`;
      document.execCommand("insertHTML", false, fileRepresentation);
    }
  };
  return (
    <div className="container reply-container">
      {loading && <Loader />}
      <Mail_fromListViewForward ticketId={ticketId} newReplies={threadData} />
      <div className="reply-header">
        <div className="email-info">
          <label>From:</label>
          <input
            type="text"
            placeholder="helpdesk.techflux@gmail.com"
            className="input-field-reply"
            disabled
          />
        </div>
        {/* <div className="email-info">
          <label>To:</label>
          <input
            type="text"
            value={ticketMail}
            className="input-field-reply"
            disabled
          />
        </div> */}

        <div className="email-info">
          <label>To:</label>
          <input
            type="text"
            value={ticketMailTo}
            className="input-field-reply"
            onChange={(e) => setTicketMailTo(e.target.value)} // Update state on change
          />
        </div>

        <div className="email-info">
          <label>CC:</label>
          <Select
            isMulti
            value={ccOptions}
            onChange={(selectedOptions) => setCcOptions(selectedOptions)}
            options={agentEmails} // Use fetched agent emails directly
            placeholder="Add CC"
            className="input-field-reply"
          />
        </div>
        <div className="email-info">
          <label>BCC:</label>
          <Select
            isMulti
            value={bccOptions}
            onChange={(selectedOptions) => setBccOptions(selectedOptions)}
            options={agentEmails} // Use fetched agent emails directly
            placeholder="Add BCC"
            className="input-field-reply"
          />
        </div>
      </div>
      <div className="reply-body">
        <div
          ref={editorRef}
          contentEditable
          className="text-area"
          placeholder="Hi Google,"
          onSelect={saveSelection}
          onFocus={() => editorRef.current.focus()}
        ></div>
        <div className="toolbar">
          <BiBold title="Bold" onClick={() => handleCommand("bold")} />
          <BiItalic title="Italic" onClick={() => handleCommand("italic")} />
          <BiUnderline
            title="Underline"
            onClick={() => handleCommand("underline")}
          />
          <span className="divider"></span>
          <BiAlignLeft
            title="Align Left"
            onClick={() => handleCommand("justifyLeft")}
          />
          <BiAlignMiddle
            title="Align Center"
            onClick={() => handleCommand("justifyCenter")}
          />
          <BiAlignRight
            title="Align Right"
            onClick={() => handleCommand("justifyRight")}
          />
          <span className="divider"></span>
          {/* <BiImage
            className="img-upload-imp"
            title="Insert Image"
            onClick={() => document.getElementById("image-upload").click()}
          /> */}
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            className="img-upload-imp"
            onChange={handleImageUpload}
          />
          <span className="divider"></span>
          <BiRedo title="Redo" onClick={() => handleCommand("redo")} />
          <BiUndo title="Undo" onClick={() => handleCommand("undo")} />
        </div>
      </div>
      <div className="reply-footer">
        <button className="send-button-reply" onClick={handleSend}>
          Send
        </button>
      </div>
      {/* <ToastContainer />  */}
    </div>
  );
};
export default Forward;
