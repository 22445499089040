import React, { useEffect, useState } from 'react';
import './RecentActivitySeaction.css';

const RecentActivity = () => {
  const [activities, setActivities] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const token = sessionStorage.getItem('TicketSystemSuperAdminToken');
  const storedRole = sessionStorage.getItem('TicketSystemSuperRoleType');

  useEffect(() => {
    const fetchRecentActivities = async () => {
      try {
        const response = await fetch('https://ticketsystem.techfluxsolutions.com/recent_activities', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (data.response) {
          // Calculate total count
          const total =
            data.ticketsCount +
            data.assignedTicketsCount +
            data.forwardTicketsCount +
            data.replyTicketsCount +
            data.noteTicketsCount;
          setTotalCount(total);

          // Format tickets data
          const ticketsActivities = data.tickets.map((ticket) => ({
            id: ticket.ticket_id,
            user:
              ticket.ticket_status === 'Open'
                ? ticket.ticket_email
                : ticket.agent_name,
            action:
              ticket.ticket_status === 'Open'
                ? 'raised'
                : ticket.ticket_status.toLowerCase(),
            ticket: ticket.ticket_subject,
            date: new Date(ticket.updated_at).toLocaleString(),
          }));

          // Format assignedTickets data
          const assignedTicketsActivities = data.assignedTickets.map(
            (assigned) => ({
              id: assigned.ticket_assignment_id,
              user: assigned.agent_name,
              action: `assigned the ticket ${assigned.ticket_status}`,
              ticket: assigned.ticket_subject,
              date: new Date(assigned.updated_at).toLocaleString(),
            })
          );

          // Format forwardTickets data
          const forwardTicketsActivities = data.forwardTickets.map((forward) => ({
            id: forward.ticket_forward_id,
            user: forward.agent_name,
            action: `forwarded the ticket`,
            ticket: forward.ticket_subject,
            date: new Date(forward.updated_at).toLocaleString(),
          }));

          // Format replyTickets data
          const replyTicketsActivities = data.replyTickets.map((reply) => ({
            id: reply.ticket_reply_id,
            user: 'Agent',
            action: `replied on`,
            ticket: reply.ticket_subject,
            date: new Date(reply.updated_at).toLocaleString(),
          }));

          // Format noteTickets data
          const noteTicketsActivities = data.noteTickets.map((note) => ({
            id: note.ticket_note_id,
            user: note.agent_name,
            action: (
              <>
                created a note <strong>{note.ticket_note}</strong> on
              </>
            ),
            ticket: note.ticket_subject,
            date: new Date(note.updated_at).toLocaleString(),
          }));

          // Combine all activities
          setActivities([
            ...ticketsActivities,
            ...assignedTicketsActivities,
            ...forwardTicketsActivities,
            ...replyTicketsActivities,
            ...noteTicketsActivities,
          ]);
        }
      } catch (error) {
        console.error('Failed to fetch recent activities:', error);
      }
    };

    fetchRecentActivities();
  }, [token, storedRole]);

  
  return (
    <div className="col-12 container-recent-activity my-4">
      <div className="d-flex align-items-center">
        <h3 className="mb-4">Recent Activities ({totalCount})</h3>
      </div>

      <div className="d-flex flex-column align-items-start">
        {activities.map((activity) => (
          <React.Fragment key={activity.id}>
            <p className="mb-2">
              <strong>{activity.user}</strong> {activity.action} the ticket{' '}
              <strong>{activity.ticket}</strong> on {activity.date}
            </p>
            <hr style={{ width: '100%' }} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default RecentActivity;
