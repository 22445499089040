import React from "react";
import { Modal, Button } from "react-bootstrap";

const AgentDeleteModal = ({ show, onHide, agent, onDelete }) => {
  const handleDelete = () => {
    if (agent) { // Check if agent is defined
      onDelete(agent.agent_id); // Call onDelete with the agent's ID
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the agent <strong>{agent?.agent_name || 'the selected agent'}</strong>?
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-cancel" onClick={onHide}>
          Cancel
        </Button>
        <Button className="btn-update" onClick={handleDelete}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AgentDeleteModal;
