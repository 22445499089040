import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Loader/Loader";
import "../CreateRolesModal/CreateRolesModal.css";

const CreateRoleModal = ({ show, onHide, onCreate, fetchRoles }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [agentType, setAgentType] = useState("");
  const [agentTypes, setAgentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkboxState, setCheckboxState] = useState({});

  const permissionCategories = [
    {
      label: "Dashboard",
      className: "dashboard",
      checkboxes: [
        { id: "alldashboard", label: "All" },
        { id: "dashboard", label: "View" }],
    },
    {
      label: "Ticket",
      className: "ticket",
      checkboxes: [
        { id: "allTicket", label: "All" },
        { id: "ticket_lists", label: "ListView" },
        { id: "ticket_kanban_lists", label: "KanbanView" },
        { id: "tickets_pdf", label: "PDF" },
        { id: "tickets_excel", label: "Excel" },
        { id: "tickets_filters", label: "Filter" },
        { id: "tickets_reply", label: "Reply" },
        { id: "ticket_forward", label: "Forward" },
        { id: "ticket_note", label: "Add Note" },
        { id: "ticket_closed", label: "Close" },
        { id: "ticket_collaboration", label: "Collaboration" },
        { id: "ticket_delete", label: "Delete" },
      ],
    },
    {
      label: "Setting",
      className: "setting",
      subcategories: [
        {
          label: "Department",
          className: "department",
          checkboxes: [
            { id: "allDepartment", label: "All" },
            { id: "settings_department_add", label: "Add" },
            { id: "settings_department_view", label: "View" },
            { id: "settings_department_edit", label: "Edit" },
            { id: "settings_department_delete", label: "Delete" },
          ],
        },
        {
          label: "Role",
          className: "role",
          checkboxes: [
            { id: "allRole", label: "All" },
            { id: "settings_role_add", label: "Add" },
            { id: "settings_role_view", label: "View" },
            { id: "settings_role_edit", label: "Edit" },
            { id: "settings_role_delete", label: "Delete" },
          ],
        },
        {
          label: "Staff",
          className: "staff",
          checkboxes: [
            { id: "allStaff", label: "All" },
            { id: "settings_staff_add", label: "Add" },
            { id: "settings_staff_view", label: "View" },
            { id: "settings_staff_edit", label: "Edit" },
            { id: "settings_staff_delete", label: "Delete" },
          ],
        },
        {
          label: "Gmail Connectivity",
          className: "gmail",
          checkboxes: [
            { id: "allgmail", label: "All" },
            { id: "settings_email_connectivity_view", label: "View" },
          ],
        },
      ],
    },
  ];


  useEffect(() => {
    if (show) {
      // Clear all the fields when the modal is opened again
      setName("");
      setDescription("");
      setAgentType("");
      const initializeCheckboxState = () => {
        const state = {};
        permissionCategories.forEach((category) => {
          category.checkboxes?.forEach((checkbox) => {
            state[checkbox.id] = 0; // Default unchecked
          });
          category.subcategories?.forEach((sub) => {
            sub.checkboxes.forEach((checkbox) => {
              state[checkbox.id] = 0;
            });
          });
        });
        setCheckboxState(state);
      };
      initializeCheckboxState();
    }
  }, [show]);
  

  const handleCheckboxChange = (
    id,
    category,
    isAll = false,
    subcategory = null
  ) => {
    setCheckboxState((prev) => {
      const newState = { ...prev };

      if (isAll) {
        // Determine the new state for "All" checkbox
        const isChecked = newState[id] === 0 ? 1 : 0;

        if (subcategory) {
          // Toggle all checkboxes in the subcategory
          subcategory.checkboxes.forEach((checkbox) => {
            newState[checkbox.id] = isChecked;
          });
        } else {
          // Toggle all checkboxes in the main category
          category.checkboxes?.forEach((checkbox) => {
            newState[checkbox.id] = isChecked;
          });
          category.subcategories?.forEach((sub) => {
            sub.checkboxes.forEach((checkbox) => {
              newState[checkbox.id] = isChecked;
            });
          });
        }
      } else {
        // Toggle individual checkbox
        newState[id] = newState[id] === 1 ? 0 : 1;

        // If a checkbox within a category is unchecked, uncheck the "All" checkbox for the category
        if (subcategory) {
          const allChecked = subcategory.checkboxes.every(
            (checkbox) => newState[checkbox.id] === 1
          );
          newState[subcategory.checkboxes.find((c) => c.label === "All").id] =
            allChecked ? 1 : 0;
        } else {
          const allChecked =
            category.checkboxes?.every(
              (checkbox) => newState[checkbox.id] === 1
            ) &&
            category.subcategories?.every((sub) =>
              sub.checkboxes.every((checkbox) => newState[checkbox.id] === 1)
            );
          const allCheckbox = category.checkboxes?.find(
            (checkbox) => checkbox.label === "All"
          );
          if (allCheckbox) {
            newState[allCheckbox.id] = allChecked ? 1 : 0;
          }
        }
      }

      return newState;
    });
  };

  // const handleSubmit = async () => {
  //   if (!name) {
  //     toast.error("Role Name is required.");
  //     return;
  //   }
  
  //   // Check if agentType is empty
  //   if (!agentType) {
  //     toast.error("Staff Type is required.");
  //     return;
  //   }
  
  //   setLoading(true);
  //   try {
  //     const selectedAgentType = agentTypes.find(
  //       (type) => type.agent_type_name === agentType
  //     );
  
  //     // Flatten the checkboxState object
  //     const flattenedPermissions = {};
  //     for (const [key, value] of Object.entries(checkboxState)) {
  //       flattenedPermissions[key] = value; // Map each permission to its key
  //     }
  
  //     const data = {
  //       role_name: name,
  //       role_description: description,
  //       agent_type_id: selectedAgentType?.agent_type_id || null,
  //       ...flattenedPermissions, // Spread the flattened permissions into the payload
  //     };
  
  //     const response = await axios.post(
  //       "https://ticketsystem.techfluxsolutions.com/add_roles",
  //       data,
  //       {
  //         headers: {
  //           Authorization: "Bearer TicketSystemSuperAdminToken",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  
  //     if (response.status === 200) {
  //       // toast.success("Role created successfully");
  //       onCreate(data);
  //       fetchRoles();
  //       onHide();
  //     } else {
  //       toast.error("Failed to create role");
  //     }
  //   } catch (error) {
  //     toast.error("Error creating role");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  const handleSubmit = async () => {
    if (!name) {
      toast.error("Role Name is required.");
      return;
    }
  
    if (!agentType) {
      toast.error("Staff Type is required.");
      return;
    }
  
    setLoading(true);
    try {
      const selectedAgentType = agentTypes.find(
        (type) => type.agent_type_name === agentType
      );
  
      // Flatten the checkboxState object
      const flattenedPermissions = {};
      for (const [key, value] of Object.entries(checkboxState)) {
        flattenedPermissions[key] = value;
      }
  
      const data = {
        role_name: name,
        role_description: description,
        agent_type_id: selectedAgentType?.agent_type_id || null,
        ...flattenedPermissions,
      };
  
      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/add_roles",
        data,
        {
          headers: {
            Authorization: "Bearer TicketSystemSuperAdminToken",
            "Content-Type": "application/json",
          },
        }
      );
  
      // Check the server's response content
      if (response.data && response.data.response === false) {
        const errorMsg = response.data.details;
        if (errorMsg && errorMsg.includes("Duplicate entry")) {
          toast.error("Role name already exists. Please choose a different name.");
        } else {
          toast.error(response.data.error_msg || "Failed to create role");
        }
      } else {
        toast.success("Role successfully created!");
        onCreate(data);
        fetchRoles();
        onHide();
      }
    } catch (error) {
      toast.error("Error creating role");
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const fetchAgentTypes = async () => {
      try {
        const response = await axios.get(
          "https://ticketsystem.techfluxsolutions.com/getAgentTypes",
          {
            headers: {
              Authorization: "Bearer TicketSystemSuperAdminToken",
            },
          }
        );
        if (response.data.response) {
          setAgentTypes(response.data.data);
        } else {
          toast.error("Failed to retrieve agent types");
        }
      } catch (error) {
        toast.error("Error fetching agent types");
      }
    };

    fetchAgentTypes();
  }, []);
  return (
    <Modal show={show} onHide={onHide} centered className="custom-role-modal">
      <Modal.Header closeButton>
        <Modal.Title>Create New Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader />
        ) : (
          <Form>
            <Form.Group controlId="roleName">
              <Form.Label>
                Role Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter role name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents default form submission
                    handleSubmit();
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="roleDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description (optional)"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents default form submission
                    handleSubmit();
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="agentType">
              <Form.Label>
                Staff Type <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={agentType}
                onChange={(e) => setAgentType(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents default form submission
                    handleSubmit();
                  }
                }}
              >
                <option value="">Select Staff Type</option>
                {agentTypes.map((type) => (
                  <option key={type.agent_type_id} value={type.agent_type_name}>
                    {type.agent_type_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <p className="text-muted mb-3">
              Note: Staff Role can't be edited once selected.
            </p>

            <div className="container">
              <div className="row row-permission parent-border1">
                {permissionCategories.map((category, index) => (
                  <div
                    key={index}
                    className={` <div className="col-12 col-md-2 parent-border dashboard ${category.className}`}
                  >
                    <label className="main-heading-checkbox text-white p-2 d-block">
                      {category.label}
                    </label>
                    {category.checkboxes?.map((checkbox) => (
                      <div className="form-check" key={checkbox.id}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={checkbox.id}
                          checked={checkboxState[checkbox.id] === 1}
                          onChange={() =>
                            handleCheckboxChange(
                              checkbox.id,
                              category,
                              checkbox.label === "All"
                            )
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevents default form submission
                              handleSubmit();
                            }
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={checkbox.id}
                        >
                          {checkbox.label}
                        </label>
                      </div>
                    ))}
                    <div className="subcategories-role">
                    {category.subcategories?.map((sub) => (
                      <div
                        key={sub.className}
                        className={`col-12 col-md-3 ${sub.className}`}
                      >
                        <label className="bg-light p-2 ">{sub.label}</label>
                        {sub.checkboxes.map((checkbox) => (
                          <div className="form-check" key={checkbox.id}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={checkbox.id}
                              checked={checkboxState[checkbox.id] === 1}
                              onChange={() =>
                                handleCheckboxChange(
                                  checkbox.id,
                                  category,
                                  checkbox.label === "All",
                                  sub
                                )
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault(); // Prevents default form submission
                                  handleSubmit();
                                }
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={checkbox.id}
                            >
                              {checkbox.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  </div>
                ))}
              </div>
            </div>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-cancel"  onClick={onHide}>
          Cancel
        </Button>
        <Button className="btn-update" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateRoleModal;
