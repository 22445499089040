
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddCollaboratorModal from '../Collaboration/AddCollaboratorModal/AddCollaboratorModal';
import Loader from '../../../Loader/Loader'; // Import your loader component
import '../Collaboration/Collaboration.css';

const Collaboration = ({ ticketId }) => { // Accept ticketId as a prop
  const [collaborators, setCollaborators] = useState([]);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/users/get_collaborators_and_assignments_by_ticket/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
          }
        }
      );

      if (response.data) {
        // Extract ticket details and collaborators from the response
        setTicketDetails({
          ticket_id: response.data.ticket.ticket_id,
          ticket_subject: response.data.ticket.ticket_subject,
        });
        setCollaborators(response.data.collaborators || []); // Ensure collaborators are an array
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchData();
  }, [ticketId, token]); // Re-run the effect when ticketId or token changes

  const handleAddCollaborator = (newCollaborators) => {
    if (!Array.isArray(newCollaborators)) {
      newCollaborators = [newCollaborators];
    }
    setCollaborators((prev) => [...prev, ...newCollaborators]);
  };

  return (
    <div className="container">
      <div className="btn-collabration-main mb-4">
        <div>
          {ticketDetails ? (
            <div>
              <h5>{ticketDetails.ticket_id}. {ticketDetails.ticket_subject}</h5>
            </div>
          ) : (
            <p>Loading ticket details...</p>
          )}
        </div>
        <button
          className="button_collaboration"
          onClick={() => setShowModal(true)}
        >
          + Add Collaborator
        </button>
      </div>

      {loading ? (
        <Loader /> // Show loader while fetching data
      ) : (
        <table className="table table-bordered mt-3">
          <thead>
            <tr>
              <th>Collaborator Name</th>
              <th>Collaborator Email</th>
            </tr>
          </thead>
          <tbody>
            {collaborators && collaborators.length > 0 ? (
              collaborators.map((collaborator, index) => (
                <tr key={index}>
                  <td>{collaborator?.agent_name || ''}</td>
                  <td>{collaborator?.agent_email || ''}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  No collaborators assigned yet.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <AddCollaboratorModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleAddCollaborator}
        ticketId={ticketId}
        fetchData={fetchData}
      />
    </div>
  );
};

export default Collaboration;
