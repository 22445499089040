import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './LogoutModal.css'

const LogoutModal = ({ show, handleClose }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Add any logout logic here if needed
        sessionStorage.clear();
        navigate('/login'); // Redirect to login or any other route after logout
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Logout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to log out?
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-cancel" onClick={handleClose}>
                    Cancel
                </Button>
                <Button className="btn-update" onClick={handleLogout}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LogoutModal;
