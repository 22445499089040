// import React from 'react'
// import HeroDashboardSection from './HeroDashboardSection/HeroDashboardSection'
// import GraphSection from './GraphSection/GraphSection'
// import RecentActivitySeaction from './RecentActivitySection/RecentActivitySeaction'

// const Dashboard = () => {
//   return (
//     <div>
//       <HeroDashboardSection/>
//       <GraphSection/>
//       <RecentActivitySeaction/>
//     </div>
//   )
// }

// export default Dashboard


import React, { useEffect, useState } from 'react';
import HeroDashboardSection from './HeroDashboardSection/HeroDashboardSection';
import GraphSection from './GraphSection/GraphSection';
import RecentActivitySeaction from './RecentActivitySection/RecentActivitySeaction';

const Dashboard = () => {
  const [hasDashboardPermission, setHasDashboardPermission] = useState(false);

  useEffect(() => {
    // Retrieve permissions from sessionStorage
    const storedPermissions = sessionStorage.getItem('TicketSystemRolePermissions');
    if (storedPermissions) {
      const permissions = JSON.parse(storedPermissions);
      // Check if dashboard permission is granted (1)
      setHasDashboardPermission(permissions.dashboard === 1);
    }
  }, []);

  // Render the dashboard only if permission is granted
  if (!hasDashboardPermission) {
    return null; // Render nothing if dashboard permission is 0
  }

  return (
    <div>
      <HeroDashboardSection />
      <GraphSection />
      <RecentActivitySeaction />
    </div>
  );
};

export default Dashboard;
