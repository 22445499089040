
import React, { useEffect, useRef, useState } from "react";
import "./Reply.css";
import { BiBold,BiItalic,BiUnderline,BiAlignLeft,BiAlignMiddle,BiAlignRight,BiImage,BiRedo,BiUndo,} from "react-icons/bi";
import Select from "react-select";
import Mail_fromListViewReply from "../Mail_fromListView/Mail_fromListViewReply";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./../../../Loader/Loader";
import axios from "axios";

const Reply = ({ ticketId, ticketMail }) => {
  const editorRef = useRef(null);
  const [selectionRange, setSelectionRange] = useState(null);
  const [ccOptions, setCcOptions] = useState([]); // CC dropdown options
  const [bccOptions, setBccOptions] = useState([]); // BCC dropdown options
  const [ccSelected, setCcSelected] = useState([]); // Selected CC values
  const [bccSelected, setBccSelected] = useState([]); // Selected BCC values
  const [isLoading, setIsLoading] = useState(false);
  const [threadData, setThreadData] = useState([]);
  const [error, setError] = useState(null);
  const [attachment, setAttachment] = useState(null);


  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      setSelectionRange(selection.getRangeAt(0));
    }
  };

  const restoreSelection = () => {
    if (selectionRange) {
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(selectionRange);
    }
  };

  const handleCommand = (command, value = null) => {
    restoreSelection();
    document.execCommand(command, false, value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachment(file); // Save the file for the attachment
      const fileRepresentation = `
        <div contenteditable="false" style="display: inline-block; padding: 5px; margin: 5px; border: 1px solid #ccc; border-radius: 4px; background-color: #f9f9f9;">
          <span style="display: flex; align-items: center;">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" fill="#555" viewBox="0 0 16 16" style="margin-right: 5px;">
              <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5v11A1.5 1.5 0 0 0 4.5 15h7a1.5 1.5 0 0 0 1.5-1.5v-9l-4-4H4.5zm4 1.5V6h3.5a.5.5 0 0 0-.5-.5H9V2.5H8.5a.5.5 0 0 0 .5.5z"/>
            </svg>
            ${file.name}
          </span>
        </div>`;
      document.execCommand("insertHTML", false, fileRepresentation);
    }
  };
  
  const fetchAgents = async () => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
    if (!token) {
      toast.error("Authentication token not found.");
      return;
    }
    try {
      const response = await axios.get(
        "https://ticketsystem.techfluxsolutions.com/agents/getAllAgentsWithSuperadmin",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const agents = response.data.data;

      // Create dropdown options for CC and BCC
      const options = agents.map((agent) => ({
        value: agent.agent_email,
        label: agent.agent_email,
      }));
      setCcOptions(options);
      setBccOptions(options);
    } catch (error) {
      toast.error("Failed to fetch agent emails.");
    }
  };

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
      if (!token) {
        throw new Error("No authentication token found in session storage.");
      }
      setIsLoading(true);

      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/getRepliesByTicketId/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);

      console.log("API Response:", response.data);

      const replies = response.data.data.replies;
      const formattedReplies = Object.values(replies).map((reply) => ({
        sender: reply.ticket_reply_cc.join(", "),
        email: reply.ticket_reply_bcc.join(", "),
        message: reply.ticket_reply_message,
        replies: [],
      }));

      setThreadData(formattedReplies);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // const sendReply = async () => {
  //   setIsLoading(true);
  //   const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
  
  //   const messageContent = editorRef.current.innerHTML;
  //   const ccEmails = ccSelected.map((option) => option.value).join(",");
  //   const bccEmails = bccSelected.map((option) => option.value).join(",");
  
  //   const formData = new FormData();
  //   formData.append("ticket_id", ticketId);
  //   formData.append("ticket_reply_cc", ccEmails);
  //   formData.append("ticket_reply_bcc", bccEmails);
  //   formData.append("ticket_reply_message", messageContent);
  
  //   // Add the attachment
  //   if (attachment) {
  //     formData.append("attachments", attachment);
  //   }
  
  //   try {
  //     const response = await fetch(
  //       "https://ticketsystem.techfluxsolutions.com/reply_ticket_old",
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: formData,
  //       }
  //     );
  
  //     const result = await response.json();
  //     setIsLoading(false);
  
  //     if (response.ok && result.response) {
  //       toast.success("Reply sent successfully!");
  //       fetchData();
        
  //       setCcSelected([]);
  //       setBccSelected([]);
  //       setAttachment(null); // Reset attachment
  //       editorRef.current.innerHTML = "";
  //     } else {
  //       toast.error(result.error_msg || "Failed to send reply.");
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     toast.error("An error occurred while sending the reply.");
  //   }
  // };
  

  const sendReply = async () => {
    setIsLoading(true);
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
  
    const messageContent = editorRef.current.innerHTML;
    const ccEmails = ccSelected.map((option) => option.value).join(",");
    const bccEmails = bccSelected.map((option) => option.value).join(",");
  
    const formData = new FormData();
    formData.append("ticket_id", ticketId);
    formData.append("ticket_reply_cc", ccEmails);
    formData.append("ticket_reply_bcc", bccEmails);
    formData.append("ticket_reply_message", messageContent);
  
    // Add the attachment
    if (attachment) {
      formData.append("attachments", attachment);
    }
  
    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/reply_ticket_old",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
  
      const result = await response.json();
      setIsLoading(false);
  
      if (response.ok && result.response) {
        toast.success("Reply sent successfully!");
        fetchData();
  
        setCcSelected([]);
        setBccSelected([]);
        setAttachment(null); // Reset attachment
        editorRef.current.innerHTML = "";
  
        // Reset the image input field (clear the preview)
        document.getElementById("image-upload").value = "";
      } else {
        toast.error(result.error_msg || "Failed to send reply.");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while sending the reply.");
    }
  };
  
  useEffect(() => {
    fetchAgents();
    fetchData();
  }, [ticketId]);

  return (
    <div className="container reply-container">
      {isLoading && <Loader />}

      <Mail_fromListViewReply ticketId={ticketId} newReplies={threadData} />

      <div className="reply-header">
        <div className="email-info">
          <label>From:</label>
          <input
            type="text"
            placeholder="helpdesk.techflux@gmail.com"
            className="input-field-reply"
            disabled
          />
        </div>
        <div className="email-info">
          <label>To:</label>
          <input
            type="text"
            value={ticketMail}
            className="input-field-reply"
            disabled
          />
        </div>
        <div className="email-info">
          <label>CC:</label>
          <Select
            isMulti
            value={ccSelected}
            onChange={(selectedOptions) => setCcSelected(selectedOptions)}
            options={ccOptions}
            placeholder="Add CC"
            className="input-field-reply"
          />
        </div>

        <div className="email-info">
          <label>BCC:</label>
          <Select
            isMulti
            value={bccSelected}
            onChange={(selectedOptions) => setBccSelected(selectedOptions)}
            options={bccOptions}
            placeholder="Add BCC"
            className="input-field-reply"
          />
        </div>
      </div>

      <div className="reply-body">
        <div
          ref={editorRef}
          contentEditable
          className="text-area"
          placeholder="Hi Google,"
          onSelect={saveSelection}
          onFocus={() => editorRef.current.focus()} >

          </div>
        <div className="toolbar">
          <BiBold title="Bold" onClick={() => handleCommand("bold")} />
          <BiItalic title="Italic" onClick={() => handleCommand("italic")} />
          <BiUnderline title="Underline" onClick={() => handleCommand("underline")} />
          <span className="divider"></span>
          <BiAlignLeft title="Align Left" onClick={() => handleCommand("justifyLeft")} />
          <BiAlignMiddle
            title="Align Center"
            onClick={() => handleCommand("justifyCenter")}
          />
          <BiAlignRight
            title="Align Right"
            onClick={() => handleCommand("justifyRight")}
          />
          <span className="divider"></span>
          {/* <BiImage
            className="img-upload-imp"
            title="Insert Image"
            onClick={() => document.getElementById("image-upload").click()}
          /> */}
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            className="img-upload-imp"
            onChange={handleImageUpload}
          />
          <span className="divider"></span>
          <BiRedo title="Redo" onClick={() => handleCommand("redo")} />
          <BiUndo title="Undo" onClick={() => handleCommand("undo")} />
        </div>
      </div>

      <div className="reply-footer">
        <button className="send-button-reply" onClick={sendReply}>
          Send
        </button>
      </div>
    </div>
  );
};

export default Reply;
