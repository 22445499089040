
import React, { useState,useEffect} from "react";
import { useNavigate } from 'react-router-dom'; 
import './Ticket_Scenario.css';
import Reply from "./Reply/Reply";
import AddNote from "./AddNote/AddNote";
import Forward from "./Forward/Forward";
import Delete from "./Delete/Delete";
import Close from "./Close/Close"; // Import the CloseModal
import { useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Collaboration from "./Collaboration/Collaboration";


const Ticket_Scenario = () => {
  const { ticketId } = useParams();
  const { ticketMail } = useParams();

  const [activeTab, setActiveTab] = useState("reply");
  const [showCloseModal, setShowCloseModal] = useState(false); // State for modal visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for Delete modal visibility
  const [rolePermissions, setRolePermissions] = useState({});
  const navigate = useNavigate();


  useEffect(() => {
    // Retrieve role permissions from sessionStorage
    const permissions = sessionStorage.getItem("TicketSystemRolePermissions");
    if (permissions) {
      setRolePermissions(JSON.parse(permissions));
    }
  }, []);
  const renderTabContent = () => {
    switch (activeTab) {
      case "reply":
        return <Reply ticketMail={ticketMail}  ticketId={ticketId} />;
      case "addNote":
        return <AddNote ticketMail={ticketMail}  ticketId={ticketId} />;
      case "forward":
        return <Forward ticketMail={ticketMail} ticketId={ticketId} />;
        case "collaboration":
        return <Collaboration ticketMail={ticketMail} ticketId={ticketId} />;
      default:
        return null;
    }
  };

  const handleCloseTabClick = () => {
    setShowCloseModal(true); // Show the modal when "Close" is clicked
  };

  const handleCloseModal = () => {
    setShowCloseModal(false); // Hide the modal
  };

  const handleDeleteTabClick = () => {
    setShowDeleteModal(true); // Show the delete modal
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(false); // Hide the delete modal
  };

  const handleDeleteTicket = () => {
    // Implement the ticket deletion logic here (API call, state update, etc.)
    console.log("Ticket deleted");
    setShowDeleteModal(false); // Hide modal after deleting
  };

  return (
    <div className="container container-ticketScenario">
      <div className="tab-buttons-ticketScenario">
      <button
        onClick={() => {
          setActiveTab("back"); // Retain the existing functionality
          navigate("/ticket"); // Add navigation to /ticket
        }}
        className={activeTab === "back" ? "active" : ""}
      >
        <IoArrowBack /> Back
      </button>

   <div className="">
   {rolePermissions.tickets_reply === 1 && (
        <button
          onClick={() => setActiveTab("reply")}
          className={activeTab === "reply" ? "active" : ""}
        >
          Reply
        </button>
       )}


{rolePermissions.ticket_note === 1 && (
        <button
          onClick={() => setActiveTab("addNote")}
          className={activeTab === "addNote" ? "active" : ""}
        >
          Add Note
        </button>
)}

{rolePermissions.ticket_forward === 1 && (
        <button
          onClick={() => setActiveTab("forward")}
          className={activeTab === "forward" ? "active" : ""}
        >
          Forward
        </button>
)}


{rolePermissions.ticket_closed === 1 && (
        <button
          onClick={handleCloseTabClick} // Trigger modal on click
          className={activeTab === "close" ? "active" : ""}
        >
          Close
        </button>
)}


{rolePermissions.ticket_delete === 1 && (
        <button
          onClick={handleDeleteTabClick} // Trigger delete modal on click
          className={activeTab === "delete" ? "active" : ""}
        >
          Delete
        </button>
)}

{rolePermissions.ticket_collaboration === 1 && (
        <button
          onClick={() => setActiveTab("collaboration")}
          className={activeTab === "collaboration" ? "active" : ""}
        >
          Collaboration
        </button>
)}
      </div>
      </div>
      <div className="tab-content">{renderTabContent()}</div>

      {/* Render the CloseModal */}
      <Close show={showCloseModal} handleClose={handleCloseModal} ticketId={ticketId} />
    {/* Render DeleteModal */}
    <Delete
        show={showDeleteModal}
        handleClose={handleDeleteModal}
        onDelete={handleDeleteTicket} // Pass the delete function
        ticketId={ticketId}
      />
      
    </div>
  );
};

export default Ticket_Scenario;

