import React, { useEffect, useState } from "react";
import ListView from "./ListView/ListView";
import KanbanView from "./KanbanView/KanbanView";
import TicketForm from "./TicketForm/TicketForm";
import Filters_Tickets from "./Filters_Tickets/Filters_Tickets";
import Clear_Filters from "./Filters_Tickets/Clear_Filters";
import { FiFilter, FiFileText, FiFile } from "react-icons/fi";
import { LuFilterX } from "react-icons/lu";
import { RxHamburgerMenu } from "react-icons/rx";
import { CiLineHeight } from "react-icons/ci";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Ensure this is imported for autoTable functionality
import * as XLSX from "xlsx";
import "./Ticket.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Ticket = () => {
  const [defaultTickets, setDefaultTickets] = useState([]);
  const [hasFetchedAgentTickets, setHasFetchedAgentTickets] = useState(false);

  const [hasFetchedSuperTickets, setHasFetchedSuperTickets] = useState(false);

  const [activeTab, setActiveTab] = useState("list");
  const [showAddNewTicket, setShowAddNewTicket] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [activeExport, setActiveExport] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showClearFilterModal, setShowClearFilterModal] = useState(false);
  const [rolePermissions, setRolePermissions] = useState({});
  const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

  useEffect(() => {
    // Retrieve role permissions from sessionStorage
    const permissions = sessionStorage.getItem("TicketSystemRolePermissions");
    if (permissions) {
      setRolePermissions(JSON.parse(permissions));
    }
  }, []);
  useEffect(() => {
    const storedItem = sessionStorage.getItem("TicketSystemSuperRoleType");
    if (storedItem) {
      setItem(storedItem);
    }
  }, []);

  const fetchSuperAdminLists_pdf_excel = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/agents/list_tickets_filters",
        {
          method: "Post",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log("Fetched data:", data); // Log the API response for debugging

      if (data.response) {
        const agentTickets = data.tickets.map((ticket, index) => ({
          id: index + 1,
          title: ticket.ticket_subject,
          dueStatus: ticket.ticket_status,
          priority: ticket.ticket_priority,
          creator: ticket.ticket_email,
          created: ticket.created_at,
          // updated: ticket.updated_at,
          // message: (ticket.ticket_message),
          agentNameByAPI: ticket.agent_name,
        }));
        console.log("Processed tickets:", agentTickets); // Log the processed data
        return agentTickets;
      } else {
        throw new Error("Failed to retrieve tickets");
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePDFDownload = async () => {
    const doc = new jsPDF();
    const tableColumn = [
      "Sr No",
      "Ticket Subject",
      "Email",
      "Agent Name",
      "Status",
      "Priority",
      "Date",
      // "Updated",
      // "Message",
    ];

    try {
      const ticketsData = await fetchSuperAdminLists_pdf_excel(); // Fetch tickets from API
      console.log("Tickets data for PDF:", ticketsData); // Log fetched tickets

      if (!ticketsData || ticketsData.length === 0) {
        alert("No tickets available for download."); // Notify user if no data
        return;
      }

      const tableRows = ticketsData.map((ticket) => [
        ticket.id,
        ticket.title,
        ticket.creator,
        ticket.agentNameByAPI,
        ticket.dueStatus,
        ticket.priority,
        ticket.created,
        // ticket.updated,
        // ticket.message,
      ]);

      doc.text("Ticket List", 10, 10); // Add a title to the PDF
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20, // Position below the title

        headStyles: {
          fillColor: [169, 169, 169], // Background color
          cellPadding: 2,
        },
        bodyStyles: {
          cellPadding: 2, // Padding for body cells
        },
        // General table styling
        tableLineColor: [0, 0, 0], // Table border color
        tableLineWidth: 0.5, // Table border width

        // Customize column styles
        columnStyles: {
          0: { cellWidth: 15, halign: "left" },
          1: { cellWidth: 35, halign: "left" },
          2: { cellWidth: 30, halign: "left" }, // First column style
          3: { cellWidth: 30, halign: "left" },
          4: { cellWidth: 25, halign: "left" },
          5: { cellWidth: 25, halign: "left" },
          6: { cellWidth: 22, halign: "left" },
          7: { cellWidth: 40, halign: "left" }, // Second column style
        },

        // Customize margin
        margin: { top: 20 },
      });

      doc.save("tickets.pdf"); // Save the PDF file
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleExcelDownload = async () => {
    try {
      // Fetch tickets from API
      const ticketsData = await fetchSuperAdminLists_pdf_excel();
      console.log("Tickets data for Excel:", ticketsData); // Log fetched tickets

      if (!ticketsData || ticketsData.length === 0) {
        alert("No tickets available for download."); // Notify user if no data
        return;
      }

      // Convert the fetched ticket data into a format suitable for Excel
      const formattedData = ticketsData.map((ticket) => ({
        "Sr No": ticket.id,
        "Ticket Subject": ticket.title,
        Email: ticket.creator,
        "Agent Name": ticket.agentNameByAPI,
        Status: ticket.dueStatus,
        Priority: ticket.priority,
        Date: ticket.created,
        // "Updated": ticket.updated, // Add more columns as needed
        // "Message": ticket.message,
      }));

      // Generate Excel file using XLSX
      const ws = XLSX.utils.json_to_sheet(formattedData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Tickets");

      // Trigger download of Excel file
      XLSX.writeFile(wb, "tickets.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  const handleExportClick = (type, exportType) => {
    setActiveExport(exportType);
    sessionStorage.setItem("activeExport", exportType);
    setActiveExport(type);
    if (type === "PDF") {
      handlePDFDownload();
    } else if (type === "Excel") {
      handleExcelDownload();
    }
  };
  useEffect(() => {
    // Get the active tab from localStorage on mount
    const savedActiveTab = sessionStorage.getItem("activeTab");
    if (savedActiveTab) {
      setActiveTab(savedActiveTab);
    }
  }, []);

  // Function to fetch tickets
  const fetchSuperAdminLists = async () => {
    try {
      const params = new URLSearchParams();

      // Example of how to use the filter params
      const type = sessionStorage.getItem("type") ?? "";

      params.append("ticket_status", type.toLowerCase());

      setIsLoading(true);
      const response = await fetch(
        `https://ticketsystem.techfluxsolutions.com/agents/list_tickets_filters?${params.toString()}`,
        {
          method: "Post",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      console.log("Data", data.tickets);

      if (data.response) {
        console.log("radhe 1", data.tickets);

        const agentTickets = data.tickets.map((ticket) => ({
          id: ticket.ticket_id,
          title: ticket.ticket_subject,
          dueStatus: ticket.ticket_status,
          priority: ticket.ticket_priority,
          creator: ticket.ticket_email,
          created: ticket.created_at,
          updated: ticket.updated_at,
          message: ticket.ticket_message,
          agentNameByAPI: ticket.agent_name,
        }));

        // Filter out duplicate tickets by ID before updating state
        setDefaultTickets((prev) => [
          ...prev,
          ...agentTickets.filter(
            (newTicket) => !prev.some((ticket) => ticket.id === newTicket.id)
          ),
        ]);
        setHasFetchedAgentTickets(true);
        setHasFetchedSuperTickets(true);
      } else {
        // toast.error("Failed to retrieve admin tickets");
      }
    } catch (error) {
      toast.error("An error occurred while fetching agent tickets");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    sessionStorage.setItem("activeTab", tab);
    setShowAddNewTicket(false);
    setShowFilters(false);
    setActiveExport(null);
  };

  const addNewTicket = (newTicket) => {
    setTickets((prevTickets) => [newTicket, ...prevTickets]);
    setShowAddNewTicket(false);
  };
  const handleClearFilters = () => {
    // Logic to clear filters
    setShowFilters(false);
    setShowClearFilterModal(false); // Close modal
    console.log("Filters cleared.");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 left-side-col">
          <div className="ticket">
            <div className="tab-buttons">
              <div className="btn-view-tickets">
                {rolePermissions.ticket_lists === 1 && (
                  <button
                    className={activeTab === "list" ? "active" : ""}
                    onClick={() => handleTabClick("list")}
                  >
                    <div className="spacing-between-btn">
                      <RxHamburgerMenu /> List
                    </div>
                  </button>
                )}

                {rolePermissions.ticket_kanban_lists === 1 && (
                  <button
                    className={activeTab === "kanban" ? "active" : ""}
                    onClick={() => handleTabClick("kanban")}
                  >
                    <CiLineHeight /> Kanban View
                  </button>
                )}
              </div>

              <div className="btn-view-tickets">
                {rolePermissions.tickets_pdf === 1 && (
                  <button
                    className={activeExport === "PDF" ? "active" : ""}
                    onClick={() => handleExportClick("PDF")}
                  >
                    <FiFileText className="mr-1" /> PDF
                  </button>
                )}

                {/* <button
                  className={activeExport === "Excel" ? "active" : ""}
                  onClick={() => handleExportClick("Excel")}
                >
                  <FiFile className="mr-1" /> Excel
                </button> */}
                {rolePermissions.tickets_excel === 1 && (
                  <button
                    className={activeExport === "Excel" ? "active" : ""}
                    onClick={() => handleExportClick("Excel")}
                  >
                    <FiFile className="mr-1" /> Excel
                  </button>
                )}

                {rolePermissions?.tickets_filters === 1 &&
                  activeTab !== "kanban" && (
                    <>
                      <button
                        className={showFilters ? "active" : ""}
                        onClick={() => {
                          setShowFilters(!showFilters);
                          setActiveExport(null);
                        }}
                      >
                        <FiFilter className="mr-1" /> Filters
                      </button>
                      <button
                        onClick={() => setShowClearFilterModal(true)} // Show modal
                      >
                        <LuFilterX className="mr-1" /> Clear Filters
                      </button>
                    </>
                  )}
              </div>
            </div>

            <div className="tab-content">
              {showAddNewTicket ? (
                <TicketForm
                  onClose={() => setShowAddNewTicket(false)}
                  addNewTicket={addNewTicket}
                />
              ) : (
                <>
                  {rolePermissions.ticket_lists === 1 &&
                    activeTab === "list" && (
                      <ListView
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        defaultTickets={defaultTickets}
                        setDefaultTickets={setDefaultTickets}
                        hasFetchedAgentTickets={hasFetchedAgentTickets}
                        setHasFetchedAgentTickets={setHasFetchedAgentTickets}
                        hasFetchedSuperTickets={hasFetchedSuperTickets}
                        setHasFetchedSuperTickets={setHasFetchedSuperTickets}
                        fetchSuperAdminLists={fetchSuperAdminLists}
                        tickets={tickets}
                        setTickets={setTickets}
                      />
                    )}

                  {activeTab === "kanban" && <KanbanView />}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showFilters && (
        <Filters_Tickets
          setTickets={setTickets}
          onClose={() => setShowFilters(false)}
        />
      )}
      <Clear_Filters
        show={showClearFilterModal}
        onHide={() => setShowClearFilterModal(false)}
        onConfirm={handleClearFilters}
        fetchSuperAdminLists={fetchSuperAdminLists}
      />
    </div>
  );
};

export default Ticket;
