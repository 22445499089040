import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Loader/Loader'; // Assuming you have a Loader component in your project

const GmailConnectivity = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://ticketsystem.techfluxsolutions.com/users/get_helpdesk', {
          headers: {
            Authorization: 'TicketSystemSuperAdminToken',
          },
        });
        setData(response.data.data); // Access the `data` array within the response
      } catch (error) {
        toast.error('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className='container'>
      <h2>Gmail Connectivity</h2>
      {loading ? (
        <Loader />
      ) : (
        <table className='table table-bordered text-center mt-4'>
          <thead>
            <tr>
              <th>Gmail</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item) => (
                <tr key={item.configuration_email_id}>
                  <td>{item.configuration_email}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No Gmail available</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default GmailConnectivity;
