
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ForgotPasswordModal from "../ForgotPasswordModal/ForgotPasswordModal";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import "./../../Credentials/Credentials.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Loader from "../../Loader/Loader";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   // Check if email is empty
  //   if (!email) {
  //     setError("Please enter your email.");
  //     toast.error("Please enter your email."); // Show toast error for empty email
  //     return;
  //   }
  
  //   // Validate email format
  //   if (!validateEmail(email)) {
  //     setError("Please enter a valid email address.");
  //     toast.error("Please enter a valid email address."); // Show toast error for invalid email
  //     return;
  //   }
  
  //   // Check if password is empty
  //   if (!password) {
  //     setError("Please enter your password.");
  //     toast.error("Please enter your password."); // Show toast error for empty password
  //     return;
  //   }
  
  //   try {
  //     setLoading(true);
  //     const response = await axios.post(
  //       "https://ticketsystem.techfluxsolutions.com/agents/login",
  //       {
  //         agent_email: email,
  //         agent_password: password,
  //       }
  //     );
  
  //     const { success_msg, error_msg, token, role_type, role_permissions } = response?.data;
  
  //     // Check for error_msg
  //     if (error_msg) {
  //       setError(error_msg);
  //       toast.error(error_msg); // Display error message in toast
  //       return;
  //     }
  
  //     // Check for success_msg
  //     if (success_msg) {
  //       toast.success(success_msg); // Display success message in toast
  //     }
  
  //     if (token) {
  //       sessionStorage.setItem("TicketSystemSuperAdminToken", token);
  //       sessionStorage.setItem("TicketSystemSuperRoleType", role_type);
  //       sessionStorage.setItem(
  //         "TicketSystemRolePermissions",
  //         JSON.stringify(role_permissions)
  //       );
  //       sessionStorage.setItem("isSuperAdminLoggedIn", "true");
  
  //       // Navigate based on role permissions
  //       if (role_permissions?.dashboard === 1) {
  //         navigate("/dashboard");
  //       } else if (role_permissions?.dashboard === 0) {
  //         navigate("/ticket");
  //       } else {
  //         const allRestricted =
  //           role_permissions?.tickets_pdf === 0 &&
  //           role_permissions?.tickets_excel === 0 &&
  //           role_permissions?.tickets_filters === 0 &&
  //           role_permissions?.tickets_reply === 0 &&
  //           role_permissions?.ticket_forward === 0 &&
  //           role_permissions?.ticket_note === 1 &&
  //           role_permissions?.ticket_closed === 0 &&
  //           role_permissions?.ticket_delete === 0 &&
  //           role_permissions?.ticket_collaboration === 0 &&
  //           role_permissions?.ticket_kanban_lists === 0 &&
  //           role_permissions?.ticket_lists === 0;
  
  //         if (allRestricted) {
  //           navigate("/setting");
  //         }
  //       }
  //     } else {
  //       setError("Login failed. Please try again.");
  //       toast.error("Login failed. Please try again.");
  //     }
  //   } catch (err) {
  //     setError("Invalid email or password. Please try again.");
  //     toast.error("Invalid email or password. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if email is empty
    if (!email) {
      setError("Please enter your email.");
      toast.error("Please enter your email."); // Show toast error for empty email
      return;
    }
  
    // Validate email format
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      toast.error("Please enter a valid email address."); // Show toast error for invalid email
      return;
    }
  
    // Check if password is empty
    if (!password) {
      setError("Please enter your password.");
      toast.error("Please enter your password."); // Show toast error for empty password
      return;
    }
  
    try {
      setLoading(true);
      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/agents/login",
        {
          agent_email: email,
          agent_password: password,
        }
      );
  
      const { success_msg, error_msg, token, role_type, role_permissions } = response?.data;
  
      // Check for error_msg
      if (error_msg) {
        setError(error_msg);
        toast.error(error_msg); // Display error message in toast
        return;
      }
  
      // Check for success_msg
      if (success_msg) {
        toast.success(success_msg); // Display success message in toast
      }
  
      if (token) {
        sessionStorage.setItem("TicketSystemSuperAdminToken", token);
        sessionStorage.setItem("TicketSystemSuperRoleType", role_type);
        sessionStorage.setItem(
          "TicketSystemRolePermissions",
          JSON.stringify(role_permissions)
        );
        sessionStorage.setItem("isSuperAdminLoggedIn", "true");
  
        // Check if all permissions are restricted (if all specified permissions are 0)
        const allRestricted =
          role_permissions?.tickets_pdf === 0 &&
          role_permissions?.tickets_excel === 0 &&
          role_permissions?.tickets_filters === 0 &&
          role_permissions?.tickets_reply === 0 &&
          role_permissions?.ticket_forward === 0 &&
          role_permissions?.ticket_note === 1 &&
          role_permissions?.ticket_closed === 0 &&
          role_permissions?.ticket_delete === 0 &&
          role_permissions?.ticket_collaboration === 0 &&
          role_permissions?.ticket_kanban_lists === 0 &&
          role_permissions?.ticket_lists === 0;
  
        // If all permissions are 0, navigate to /setting
        if (allRestricted) {
          navigate("/setting");
        } else {
          // Navigate based on role permissions
          if (role_permissions?.dashboard === 1) {
            navigate("/dashboard");
          } else {
            navigate("/ticket");
          }
        }
      } else {
        setError("Login failed. Please try again.");
        toast.error("Login failed. Please try again.");
      }
    } catch (err) {
      setError("Invalid email or password. Please try again.");
      toast.error("Invalid email or password. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  
  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container container-credentials">
      <ToastContainer /> {/* Include this to enable toast notifications */}
      <div className="row">
        <div className="row-Credentials">
          <div className="col-12 col-md-6 inner-container-credentials">
            <p className="heading-credentials">Login to support portal</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label form-label-color">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control form-credentials"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
  
              <div className="mb-3 position-relative">
                <label htmlFor="password" className="form-label form-label-color">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control form-credentials"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="password-toggle"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </span>
              </div>
  
              {error && <div className="text-danger mb-3">{error}</div>}
  
              <div>
                <button type="submit" className="btn btn-credentials">
                  Login
                </button>
                <div className="flex-credentials">
                  <p
                    className="user-login-credentials"
                    onClick={handleForgotPasswordClick}
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password?
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  
      <ForgotPasswordModal
        show={showForgotPasswordModal}
        handleClose={handleCloseForgotPasswordModal}
      />
    </div>
  );
  
};

export default Login;
