import React from "react";
import { Modal, Button } from "react-bootstrap";

const Clear_Filters = ({ show, onHide, fetchSuperAdminLists }) => {
  const handleConfirm = () => {
    sessionStorage.removeItem("type"); // Clear the session storage
    sessionStorage.removeItem("priority");
    sessionStorage.removeItem("closing date");
    sessionStorage.removeItem("resolved date");
    fetchSuperAdminLists(); // Call the fetch function
    onHide(); // Close the modal
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Clear Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to clear all filters?
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-cancel" onClick={onHide}>
          Cancel
        </Button>
        <Button className="btn-update" onClick={handleConfirm}>
          Clear Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Clear_Filters;
