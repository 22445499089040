import React, { useState } from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import { FaBars, FaBell, FaUser } from "react-icons/fa";
import { HiOutlineTicket } from "react-icons/hi2";
import LogoutModal from "../../Pages/LogoutModal/LogoutModal"; // Import the LogoutModal component
import "./NavbarComponent.css";
import { useNavigate } from "react-router-dom";

const NavbarComponent = ({ toggleSidebar, pageTitle, isOpen }) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState(1); // State for notification count
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to track dropdown visibility

  const handleShowLogoutModal = () => setShowLogoutModal(true);
  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  const navigate = useNavigate();

  const handleNotificationClick = () => {
    // Toggle dropdown visibility when notification icon is clicked
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Navbar className="Navbar-TicketSysteam" expand="lg">
      <Container fluid>
        <div className="navbar-spacing">
          {/* <Navbar.Brand className='Navbar-components' href="#">
                        <HiOutlineTicket className="icon" />
                        {isOpen && <span className="logo-text">Ticket System</span>}
                    </Navbar.Brand> */}
          <Navbar.Brand
            className="Navbar-components"
            onClick={() => navigate("/dashboard")} // Add onClick to navigate
            style={{ cursor: "pointer" }} // Add cursor pointer to indicate it's clickable
          >
            <HiOutlineTicket className="icon" />
            {isOpen && <span className="logo-text">Ticket System</span>}
          </Navbar.Brand>

          <FaBars className="hamburger-navbar" onClick={toggleSidebar} />
          <Navbar.Brand className="Navbar-components" href="#">
            {pageTitle}
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse className="navbar-nav-subcomponents">
          <Nav className="navbar-subcomponents ml-auto">
            {/* Notifications without dropdown arrow */}
            {/* <Dropdown show={dropdownOpen} onToggle={handleNotificationClick} align="end" className='Navbar-childrens'>
                            <Nav.Link onClick={handleNotificationClick} className='Navbar-childrens'>
                                <FaBell className="bell-icon" />
                                <span className="notification-circle">
                                    {notificationCount}
                                </span>
                            </Nav.Link>
                            <Dropdown.Menu className='droupdown-menu-childrens'>
                                <Dropdown.Item href="#action/3.1">Notification 1</Dropdown.Item>
                                <Dropdown.Item href="#action/3.2">Notification 2</Dropdown.Item>
                                <Dropdown.Item href="#action/3.3">Notification 3</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}

            {/* Profile dropdown */}
            <Dropdown align="end" className="Navbar-childrens">
              <Dropdown.Toggle as={Nav.Link} className="Navbar-childrens">
                <FaUser className="user-icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="droupdown-menu-childrens">
                <Dropdown.Item onClick={() => navigate("/profile")}>
                  Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={handleShowLogoutModal}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>

      {/* Render LogoutModal and pass show and handleClose props */}
      <LogoutModal
        show={showLogoutModal}
        handleClose={handleCloseLogoutModal}
      />
    </Navbar>
  );
};

export default NavbarComponent;
