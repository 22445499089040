import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./KanbanView.css";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../Loader/Loader";

import {
  FaUser,
  FaCalendarAlt,
  FaEnvelope,
  FaChevronDown,
} from "react-icons/fa";

const PRIORITIES = [
  { label: "Low", value: "Low", color: "green" },
  { label: "Medium", value: "Medium", color: "blue" },
  { label: "High", value: "High", color: "orange" },
  { label: "Urgent", value: "Urgent", color: "red" },
];

const KanbanView = () => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null); // State to store user role
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    const fetchRole = () => {
      const storedItem = sessionStorage.getItem("TicketSystemSuperRoleType");
      setRole(storedItem);
    };

    const fetchTicketsForSuperadmin = async () => {
      try {
        setLoading(true);
        const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
        const response = await fetch(
          "https://ticketsystem.techfluxsolutions.com/agents/get_kanban_tickets_filters",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();

        if (data.response) {
          const fetchedCards = [];
          Object.keys(data.data).forEach((status) => {
            data.data[status].forEach((ticket) => {
              fetchedCards.push({
                id: ticket.ticket_id.toString(),
                title: ticket.ticket_subject,
                status: ticket.ticket_status,
                priority: ticket.ticket_priority,
                agent: ticket.agent_name,
                created_at: ticket.created_at,
                user_email: ticket.ticket_email,
                color:
                  ticket.ticket_priority === "Low"
                    ? "#7bed9f"
                    : ticket.ticket_priority === "Medium"
                    ? "#ff6b81"
                    : ticket.ticket_priority === "High"
                    ? "#70a1ff"
                    : "#ff6347",
                message: ticket.ticket_message,
                creator: ticket.ticket_email,
              });
            });
          });

          setCards(fetchedCards);
        }
      } catch (error) {
        console.error("Error fetching tickets for superadmin:", error);
      } finally {
        setLoading(false);
      }
    };

    const initializeData = async () => {
      fetchRole(); // Fetch user role
      fetchTicketsForSuperadmin(); // Fetch tickets

      // Fetch agents and set state
      const agentsList = await fetchAgents();
      setAgents(agentsList);
    };

    initializeData();
  }, []);

  const updateTicketPriority = async (ticketId, priority) => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/users/update_priority_status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ticket_id: ticketId,
            ticket_priority: priority,
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data) {
        console.log("Priority updated successfully:", data);
        return true; // Indicate success
      } else {
        console.error("Failed to update priority:", data);
        return false; // Indicate failure
      }
    } catch (error) {
      console.error("Error updating priority:", error);
      return false; // Indicate failure
    }
  };

  const fetchAgents = async () => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/agents/getAllAgents_new",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (response.ok && data.response) {
        console.log("Agents retrieved successfully:", data.data);
        return data.data; // Return the agents list
      } else {
        console.error("Failed to fetch agents:", data);
        return []; // Return an empty array on failure
      }
    } catch (error) {
      console.error("Error fetching agents:", error);
      return []; // Return an empty array on error
    }
  };

  const assignTicketToAgent = async (ticketId, agentId) => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/assign_ticket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ticket_id: ticketId,
            agent_id: agentId,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log("Ticket assigned successfully:", data);
        return data; // Return the API response
      } else {
        console.error("Failed to assign ticket:", data);
        alert("Failed to assign ticket. Please try again.");
        return null;
      }
    } catch (error) {
      console.error("Error assigning ticket:", error);
      alert("An error occurred while assigning the ticket.");
      return null;
    }
  };

  const handleCardClick = (cardId, creator) => {
    navigate(`/ticket-scenario/${cardId}/${creator}`);
  };

  const handleDragStart = (ev) => {
    ev.dataTransfer.setData("text/plain", ev.target.id);

    const cardWrappers = document.getElementsByClassName(
      "kanban-cards-wrapper"
    );
    Array.from(cardWrappers).forEach((c) => {
      c.classList.add("card-placeable");
    });
  };

  const handleDragEnd = () => {
    const cardWrappers = document.getElementsByClassName(
      "kanban-cards-wrapper"
    );
    Array.from(cardWrappers).forEach((c) => {
      c.classList.remove("card-placeable");
    });
  };

  const handleDragOver = (ev) => {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = "move";
  };

  const updateTicketStatus = async (ticketId, status) => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/update_ticket_status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ticket_id: ticketId,
            ticket_status: status,
          }),
        }
      );

      const data = await response.json();

      if (response.ok && data) {
        console.log("Status updated successfully:", data);
        return true;
      } else {
        console.error("Failed to update status:", data);
        return false;
      }
    } catch (error) {
      console.error("Error updating status:", error);
      return false;
    }
  };
  const handleDrop = async (ev, status) => {
    ev.preventDefault();
    const cardId = ev.dataTransfer.getData("text/plain");

    const isUpdated = await updateTicketStatus(cardId, status);

    if (isUpdated) {
      const updatedCards = cards.map((card) =>
        card.id === cardId ? { ...card, status } : card
      );
      setCards(updatedCards);
    } else {
      alert("Failed to update ticket status. Please try again.");
    }
  };

  const columns = ["Open", "Pending", "Resolved", "Closed"];

  return loading ? (
    <Loader />
  ) : (
    <div className="kanban-wrapper">
      {columns.map((column) => (
        <div
          className="kanban-container"
          key={column}
          onDragOver={handleDragOver}
          onDrop={(ev) => handleDrop(ev, column)}
        >
          <h1 className="kanban-column-title">{column}</h1>

          <div
            className="kanban-cards-wrapper"
            onDragOver={handleDragOver}
            onDrop={(ev) => handleDrop(ev, column)}
          >
            
            {cards
              .filter((card) => card.status === column)
              .map((card) => (
                <div
                  key={card.id}
                  draggable="true"
                  id={card.id}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  className="kanban-card"
                >
                  <div className="kanban-type">
                    <span
                      className="kanban-title"
                      style={{ color: "#12344D" }}
                      onClick={() => handleCardClick(card.id, card.creator)}
                    >
                     {card.id}. {card.title}
                    </span>
                  </div>
                  <div className="kanban-content-section">
                    <div className="kanban-email-row">
                      <FaUser className="kanban-icon" />
                      {card.user_email}
                    </div>

                    <div className="kanban-email-row">
                      <div className="kanban-email-row-chaild">
                        <FaEnvelope className="kanban-icon" />
                      </div>
                      <div style={{ flex: 1 }}>
                        <span
                          dangerouslySetInnerHTML={{ __html: card.message }}
                        />
                      </div>
                    </div>

                    <div className="kanban-footer">
                      <div className="kanban-footer-item">
                        <FaCalendarAlt className="kanban-icon" />
                        {card.created_at}
                      </div>
                      <div className="kanban-footer-agent-priority">
                        <div
                          className="kanban-agent-dropdown"
                          style={{
                            display: role === "agent" ? "none" : "block",
                          }}
                        >
                          <select
                            className="kanban-agent-select"
                            style={{ cursor:"pointer" }}
                            value={card.agent || ""}
                            onChange={async (e) => {
                              const selectedAgent = e.target.value;
                              const selectedAgentId = agents.find(
                                (agent) => agent.agent_name === selectedAgent
                              )?.agent_id;

                              if (selectedAgentId) {
                                const result = await assignTicketToAgent(
                                  card.id,
                                  selectedAgentId
                                );
                                if (result) {
                                  // Update the card with the assigned agent's name
                                  setCards(
                                    cards.map((c) =>
                                      c.id === card.id
                                        ? { ...c, agent: selectedAgent }
                                        : c
                                    )
                                  );
                                }
                              }
                            }}
                          >
                            <option value="" disabled>
                              Select..
                            </option>
                            {agents.map((agent) => (
                              <option
                                key={agent.agent_id}
                                value={agent.agent_name}
                              >
                                {agent.agent_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="kanban-priority-dropdown">
                          <select
                            className={`kanban-priority-select ${card.priority.toLowerCase()}`}
                            style={{ cursor:"pointer" }}
                            value={card.priority}
                            onChange={async (e) => {
                              const newPriority = e.target.value;

                              const isUpdated = await updateTicketPriority(
                                card.id,
                                newPriority
                              );
                              if (isUpdated) {
                                setCards(
                                  cards.map((c) =>
                                    c.id === card.id
                                      ? { ...c, priority: newPriority }
                                      : c
                                  )
                                );
                              } else {
                                alert(
                                  "Failed to update priority. Please try again."
                                );
                              }
                            }}
                          >
                            {PRIORITIES.map((priority) => (
                              <option
                                key={priority.value}
                                value={priority.value}
                                style={{ color: priority.color }}
                              >
                                {priority.label}
                              </option>
                            ))}
                          </select>

                          <FaChevronDown className="dropdown-icon-kanban" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default KanbanView;
