import React, { useState, useEffect } from 'react';
import './EditProfile.css'; 
import axios from 'axios'; 
import { toast,ToastContainer } from 'react-toastify'; 
import Loader from '../../../Loader/Loader'; 

const EditProfile = ({ userData, onClose, onUpdate, fetchUserProfile }) => {
  const [isVisible, setIsVisible] = useState(false); 
  const [draftData, setDraftData] = useState({ ...userData }); 
  const [loading, setLoading] = useState(false);

  useEffect(() => {
   
    setIsVisible(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
   
    // toast.info('Updating your profile...');
    toast.success('Profile updated successfully!');

    const token = sessionStorage.getItem('TicketSystemSuperAdminToken');

    try {
      // Make API call to update profile
      const response = await axios.post(
        'https://ticketsystem.techfluxsolutions.com/agents/editProfile',
        {
          agent_name: draftData.name,
          agent_email: draftData.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, 
          },
        }
      );

    
      if (response.data) {
        // Assuming the response contains the updated data
        onUpdate({
          name: response.data.agent_name,
          email: response.data.agent_email,
        });

       
        toast.success('Profile updated successfully!');

       
        fetchUserProfile();

        handleClose(); 
      }
    } catch (error) {
      // Handle error
      toast.error('Failed to update profile.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
 
    setIsVisible(false);
    setTimeout(() => {
      onClose(); 
    }, 300);
  };

  return (
    <div className={`edit-profile-container ${isVisible ? 'slide-in' : 'slide-out'}`}>
      {/* {loading && <Loader />} */}
      <ToastContainer />

      <div className="edit-profile-content">
        <h3>Edit Profile</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={draftData.name}
              onChange={(e) => setDraftData({ ...draftData, name: e.target.value })}
              disabled={loading} 
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={draftData.email}
              onChange={(e) => setDraftData({ ...draftData, email: e.target.value })}
              disabled={loading} 
            />
          </div>
          <div className="button-group">
            <button type="submit" className="btn-update" disabled={loading}>
              Update Changes
            </button>
            <button type="button" onClick={handleClose} className="btn-cancel">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
