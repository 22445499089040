import React, { useState, useEffect } from 'react';
import Department from './Department/Department';
import Roles from './Roles/Roles';
import Agents from './Agents/Agents';
import GmailConnectivity from './GmailConnectivity/GmailConnectivity';
import './Settings.css'; // Optional: Add your own styles

const Settings = () => {
  const [activeTab, setActiveTab] = useState('department');
  const [rolePermissions, setRolePermissions] = useState({});
  const hasDepartmentPermission =
        rolePermissions.settings_department_add === 1 ||
        rolePermissions.settings_department_edit === 1 ||
        rolePermissions.settings_department_delete === 1 ||
        rolePermissions.settings_department_view === 1;

  const hasRolePermission =
        rolePermissions.settings_role_add === 1 ||
        rolePermissions.settings_role_edit === 1 ||
        rolePermissions.settings_role_delete === 1 ||
        rolePermissions.settings_role_view === 1;      


   const hasStaffPermission =
        rolePermissions.settings_staff_add === 1 ||
        rolePermissions.settings_staff_edit === 1 ||
        rolePermissions.settings_staff_delete === 1 ||
        rolePermissions.settings_staff_view === 1;

 useEffect(() => {
   // Retrieve role permissions from sessionStorage
   const permissions = sessionStorage.getItem("TicketSystemRolePermissions");
   if (permissions) {
     setRolePermissions(JSON.parse(permissions));
   }
 }, []);

  // Load the tab from localStorage on component mount
  useEffect(() => {
    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('activeTab', tab); // Save tab to localStorage
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'department':
        // return <Department />;
        return hasDepartmentPermission ? <Department /> : null;
      case 'roles':
        // return <Roles />;
        return hasRolePermission ? <Roles /> : null;
      case 'agents':
        // return <Agents />;
        return hasStaffPermission ? <Agents /> : null;
      case 'gmail':
        return rolePermissions.settings_email_connectivity_view === 1 ? (
          <GmailConnectivity />
        ) : null;
      default:
        return <Department />;
    }
  };
  

  return (
    <div className="settings-container">
      <div className="sidebar-settings">
        <form>
        {hasDepartmentPermission && (
          <label>
            <input
              type="radio"
              name="tab"
              className="radio-settings"
              value="department"
              checked={activeTab === 'department'}
              onChange={() => handleTabChange('department')}
            />
            Department
          </label>
        )}

{hasRolePermission && (
          <label>
            <input
              type="radio"
              name="tab"
              className="radio-settings"
              value="roles"
              checked={activeTab === 'roles'}
              onChange={() => handleTabChange('roles')}
            />
            Roles
          </label>
)}

{hasStaffPermission && (
          <label>
            <input
              type="radio"
              name="tab"
              className="radio-settings"
              value="agents"
              checked={activeTab === 'agents'}
              onChange={() => handleTabChange('agents')}
            />
            Staff
          </label>
)}

          {rolePermissions.settings_email_connectivity_view === 1 && (
          <label>
            <input
              type="radio"
              name="tab"
              className="radio-settings"
              value="gmail"
              checked={activeTab === 'gmail'}
              onChange={() => handleTabChange('gmail')}
            />
            Gmail Connectivity
          </label>
          )}
        </form>
      </div>

      <div className="content-settings">
        {renderContent()}
      </div>
    </div>
  );
};

export default Settings;
