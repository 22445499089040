
// import React, { useEffect, useState } from 'react';
// import { FaHome, FaTicketAlt, FaTachometerAlt } from 'react-icons/fa';
// import { MdOutlineSettings } from "react-icons/md";
// import { Link, useLocation } from 'react-router-dom';
// import './SidebarComponent.css';

// const SidebarComponent = ({ isOpen }) => {
//     const location = useLocation();
//     const [item, setItem] = useState(null);

//     useEffect(() => {
//         // Retrieve the item from sessionStorage
//         const storedItem = sessionStorage.getItem('TicketSystemSuperRoleType');
//         if (storedItem) {
//             setItem(storedItem); // Update state if the item exists
//         }
//     }, []);

    

//     return (
//         <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
//             <ul className="SidebarComponets_TS">
//                 <div className="link-wrapper">
//                     <Link to="/dashboard" className="no-underline">
//                         <li className={`List_Sidebar ${location.pathname === '/dashboard' ? 'active' : ''}`}>
//                             <FaTachometerAlt className="icon" />
//                             {isOpen && <span className='pages_sidebar_Ts'>Dashboard</span>}
//                         </li>
//                     </Link>
//                 </div>
                
//                 <div className="link-wrapper">
//                     <Link to="/ticket" className="no-underline">
//                         <li className={`List_Sidebar ${location.pathname === '/ticket' ? 'active' : ''}`}>
//                             <FaTicketAlt className="icon" />
//                             {isOpen && <span>Tickets</span>}
//                         </li>
//                     </Link>
//                 </div>
                
              
//                 {/* Render Settings only if role type is not "agent" */}
//                 {/* {item !== 'agent' && ( */}
//                     <div className="link-wrapper">
//                         <Link to="/setting" className="no-underline">
//                             <li className={`List_Sidebar ${location.pathname === '/setting' ? 'active' : ''}`}>
//                                 <MdOutlineSettings className="icon" />
//                                 {isOpen && <span>Settings</span>}
//                             </li>
//                         </Link>
//                     </div>
//                 {/* )} */}
//             </ul>
//         </div>
//     );
// };

// export default SidebarComponent;





import React, { useEffect, useState } from 'react';
import { FaHome, FaTicketAlt, FaTachometerAlt } from 'react-icons/fa';
import { MdOutlineSettings } from "react-icons/md";
import { Link, useLocation } from 'react-router-dom';
import './SidebarComponent.css';

const SidebarComponent = ({ isOpen }) => {
    const location = useLocation();
    const [item, setItem] = useState(null);
    const [rolePermissions, setRolePermissions] = useState(null);
    const hasSettingsPermission = rolePermissions && (
        rolePermissions.settings_department_add === 1 ||
        rolePermissions.settings_department_edit === 1 ||
        rolePermissions.settings_department_delete === 1 ||
        rolePermissions.settings_department_view === 1 ||
        rolePermissions.settings_role_add === 1 ||
        rolePermissions.settings_role_edit === 1 ||
        rolePermissions.settings_role_delete === 1 ||
        rolePermissions.settings_role_view === 1 ||
        rolePermissions.settings_staff_add === 1 ||
        rolePermissions.settings_staff_edit === 1 ||
        rolePermissions.settings_staff_delete === 1 ||
        rolePermissions.settings_staff_view === 1 ||
        rolePermissions.settings_email_connectivity_view === 1
    );

    const hasTicketsPermission = rolePermissions && (
        rolePermissions.tickets_pdf === 1 ||
        rolePermissions.tickets_excel === 1 ||
        rolePermissions.tickets_filters === 1 ||
        rolePermissions.tickets_reply === 1 ||
        rolePermissions.ticket_forward === 1 ||
        rolePermissions.ticket_note === 1 ||
        rolePermissions.ticket_closed === 1 ||
        rolePermissions.ticket_delete === 1 ||
        rolePermissions.ticket_collaboration === 1 ||
        rolePermissions.ticket_kanban_lists === 1 ||
        rolePermissions.ticket_lists === 1     
    );

    useEffect(() => {
        // Retrieve the item from sessionStorage
        const storedItem = sessionStorage.getItem('TicketSystemSuperRoleType');
        if (storedItem) {
            setItem(storedItem); // Update state if the item exists
        }

        // Retrieve the TicketSystemRolePermissions from sessionStorage
        const storedPermissions = sessionStorage.getItem('TicketSystemRolePermissions');
        if (storedPermissions) {
            setRolePermissions(JSON.parse(storedPermissions)); // Assuming the permissions are stored as a JSON string
        }
    }, []);

    return (
        <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
            <ul className="SidebarComponets_TS">
            {rolePermissions?.dashboard === 1 && (
                    <div className="link-wrapper">
                        <Link to="/dashboard" className="no-underline">
                            <li className={`List_Sidebar ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                                <FaTachometerAlt className="icon" />
                                {isOpen && <span className='pages_sidebar_Ts'>Dashboard</span>}
                            </li>
                        </Link>
                    </div>
                )}
                
                {hasTicketsPermission && (
                <div className="link-wrapper">
                    <Link to="/ticket" className="no-underline">
                        <li className={`List_Sidebar ${location.pathname === '/ticket' ? 'active' : ''}`}>
                            <FaTicketAlt className="icon" />
                            {isOpen && <span>Tickets</span>}
                        </li>
                    </Link>
                </div>
                )}


                {hasSettingsPermission && (
                    <div className="link-wrapper">
                        <Link to="/setting" className="no-underline">
                            <li className={`List_Sidebar ${location.pathname === '/setting' ? 'active' : ''}`}>
                                <MdOutlineSettings className="icon" />
                                {isOpen && <span>Settings</span>}
                            </li>
                        </Link>
                    </div>
                )}
            </ul>
        </div>
    );
};

export default SidebarComponent;