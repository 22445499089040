
import React from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Close = ({ show, handleClose, ticketId }) => {
  const navigate = useNavigate();

  const handleConfirmClose = async () => {
    try {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      if (!token) {
        throw new Error("No authentication token found.");
      }

      // Send the API request to update the ticket status
      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/users/update_status",
        {
          ticket_id: ticketId,
          status: "closed", // Or other status as needed
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.response) {
        // Show a success toast message
        toast.success("Ticket status updated to closed successfully!");

        // Close the modal and navigate to the ticket page
        handleClose();
        navigate("/ticket");
      } else {
        console.error("Failed to close the ticket:", response.data.message);
      }
    } catch (error) {
      console.error("Error closing ticket:", error);
      toast.error("Failed to update ticket status.");
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Ticket Closure</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to close this ticket?</Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn-update" onClick={handleConfirmClose}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast container for displaying toast notifications */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </>
  );
};

export default Close;
