import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "./../../../Loader/Loader";

const EditRoleModal = ({ show, onHide, role, fetchRoles, roleIdData }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [agentName, setAgentName] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({
    dashboard: 0,
    settings_department_add: 0,
    settings_department_edit: 0,
    settings_department_delete: 0,
    settings_role_add: 0,
    settings_role_edit: 0,
    settings_role_delete: 0,
    settings_role_view: 0,
    settings_department_view: 0,
    settings_staff_add: 0,
    settings_staff_edit: 0,
    settings_staff_delete: 0,
    settings_staff_view: 0,
    settings_email_connectivity_view: 0,
    ticket_lists: 0,
    ticket_kanban_lists: 0,
    tickets_pdf: 0,
    tickets_excel: 0,
    tickets_filters: 0,
    tickets_reply: 0,
    ticket_forward: 0,
    ticket_note: 0,
    ticket_closed: 0,
    ticket_delete: 0,
    ticket_collaboration: 0,
  });
  useEffect(() => {
    if (role) {
      setName(role.role_name || "");
      setDescription(role.role_description || "");
      setAgentName(role.agent_type_name || "");

      // Update checkboxStates based on role permissions
      setCheckboxStates({
        dashboard: role.dashboard === 1 ? 1 : 0,
        settings_department_add: role.settings_department_add === 1 ? 1 : 0,
        settings_department_edit: role.settings_department_edit === 1 ? 1 : 0,
        settings_department_delete:
          role.settings_department_delete === 1 ? 1 : 0,
        settings_role_add: role.settings_role_add === 1 ? 1 : 0,
        settings_role_edit: role.settings_role_edit === 1 ? 1 : 0,
        settings_role_delete: role.settings_role_delete === 1 ? 1 : 0,
        settings_role_view: role.settings_role_view === 1 ? 1 : 0,
        settings_department_view: role.settings_department_view === 1 ? 1 : 0,
        settings_staff_add: role.settings_staff_add === 1 ? 1 : 0,
        settings_staff_edit: role.settings_staff_edit === 1 ? 1 : 0,
        settings_staff_delete: role.settings_staff_delete === 1 ? 1 : 0,
        settings_staff_view: role.settings_staff_view === 1 ? 1 : 0,
        settings_email_connectivity_view:
          role.settings_email_connectivity_view === 1 ? 1 : 0,
        ticket_kanban_lists: role.ticket_kanban_lists === 1 ? 1 : 0,
        ticket_lists: role.ticket_lists === 1 ? 1 : 0,
        tickets_pdf: role.tickets_pdf === 1 ? 1 : 0,
        tickets_excel: role.tickets_excel === 1 ? 1 : 0,
        tickets_filters: role.tickets_filters === 1 ? 1 : 0,
        tickets_reply: role.tickets_reply === 1 ? 1 : 0,
        ticket_forward: role.ticket_forward === 1 ? 1 : 0,
        ticket_note: role.ticket_note === 1 ? 1 : 0,
        ticket_closed: role.ticket_closed === 1 ? 1 : 0,
        ticket_delete: role.ticket_delete === 1 ? 1 : 0,
        ticket_collaboration: role.ticket_collaboration === 1 ? 1 : 0,
      });
    }
  }, [role]); // Trigger when the role is selected or updated

  // Initialize checkbox states based on role's permissions
  const initializeCheckboxStates = (permissions) => {
    const initialStates = {};

    permissions.forEach((permission) => {
      initialStates[permission] = 1; // Assume checked by default if the permission exists
    });

    setCheckboxStates(initialStates);
  };

  // const handleCheckboxChange = (event, permission) => {
  //   setCheckboxStates((prevStates) => ({
  //     ...prevStates,
  //     [permission]: event.target.checked ? 1 : 0,
  //   }));
  // };
  const toggleAllCheckboxes = (event, category, subcategory = null) => {
    const isChecked = event.target.checked; // Whether the "All" checkbox is checked or unchecked.
    let checkboxes;

    if (subcategory) {
      // If there's a subcategory, find the checkboxes within that subcategory.
      checkboxes = document.querySelectorAll(
        `.${subcategory} .form-check-input`
      );
    } else {
      // Otherwise, find the checkboxes in the main category.
      checkboxes = document.querySelectorAll(`.${category} .form-check-input`);
    }

    checkboxes.forEach((checkbox) => {
      checkbox.checked = isChecked; // Check/uncheck each checkbox based on the "All" checkbox state.
      const permission = checkbox.id;
      handleCheckboxChange({ target: { checked: isChecked } }, permission); // Call the handleCheckboxChange function.
    });
  };

  const handleCheckboxChange = (event, permission) => {
    setCheckboxStates((prevStates) => ({
      ...prevStates,
      [permission]: event.target.checked ? 1 : 0, // Update the state for each permission.
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    const payload = {
      role_id: role?.role_id,
      role_name: name || role?.role_name,
      role_description: description || role?.role_description,
      agent_type_id: role ? role.agent_type_id : null,
      dashboard: checkboxStates.dashboard,
      settings_department_add: checkboxStates.settings_department_add,
      settings_department_edit: checkboxStates.settings_department_edit,
      settings_department_delete: checkboxStates.settings_department_delete,
      settings_role_add: checkboxStates.settings_role_add,
      settings_role_edit: checkboxStates.settings_role_edit,
      settings_role_delete: checkboxStates.settings_role_delete,
      settings_role_view: checkboxStates.settings_role_view,
      settings_department_view: checkboxStates.settings_department_view,
      settings_staff_add: checkboxStates.settings_staff_add,
      settings_staff_edit: checkboxStates.settings_staff_edit,
      settings_staff_delete: checkboxStates.settings_staff_delete,
      settings_staff_view: checkboxStates.settings_staff_view,
      settings_email_connectivity_view:
        checkboxStates.settings_email_connectivity_view,
      ticket_lists: checkboxStates.ticket_lists,
      ticket_kanban_lists: checkboxStates.ticket_kanban_lists,
      tickets_pdf: checkboxStates.tickets_pdf,
      tickets_excel: checkboxStates.tickets_excel,
      tickets_filters: checkboxStates.tickets_filters,
      tickets_reply: checkboxStates.tickets_reply,
      ticket_forward: checkboxStates.ticket_forward,
      ticket_note: checkboxStates.ticket_note,
      ticket_closed: checkboxStates.ticket_closed,
      ticket_delete: checkboxStates.ticket_delete,
      ticket_collaboration: checkboxStates.ticket_collaboration,
    };

    try {
      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/update_roles",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response:", response);
      if (response.data.response) {
        toast.success("Role updated successfully");
        fetchRoles();
        onHide();
      } else {
        toast.error("Failed to update role");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to update role");
    } finally {
      setLoading(false);
    }
  };

  const permissionCategories = [
    {
      label: "Dashboard",
      className: "dashboard",
      checkboxes: [{ id: "dashboard", label: "View" }],
    },
    {
      label: "Ticket",
      className: "ticket",
      checkboxes: [
        // { id: "allTicket", label: "All" },
        { id: "ticket_lists", label: "ListView" },
        { id: "ticket_kanban_lists", label: "KanbanView" },
        { id: "tickets_pdf", label: "PDF" },
        { id: "tickets_excel", label: "Excel" },
        { id: "tickets_filters", label: "Filter" },
        { id: "tickets_reply", label: "Reply" },
        { id: "ticket_forward", label: "Forward" },
        { id: "ticket_note", label: "Add Note" },
        { id: "ticket_closed", label: "Close" },
        { id: "ticket_collaboration", label: "Collaboration" },
        { id: "ticket_delete", label: "Delete" },
      ],
    },
    {
      label: "Setting",
      className: "setting",
      subcategories: [
        {
          label: "Department",
          className: "department",
          checkboxes: [
            // { id: "allDepartment", label: "All" },
            { id: "settings_department_add", label: "Add" },
            { id: "settings_department_view", label: "View" },
            { id: "settings_department_edit", label: "Edit" },
            { id: "settings_department_delete", label: "Delete" },
          ],
        },
        {
          label: "Role",
          className: "role",
          checkboxes: [
            // { id: "allRole", label: "All" },
            { id: "settings_role_add", label: "Add" },
            { id: "settings_role_view", label: "View" },
            { id: "settings_role_edit", label: "Edit" },
            { id: "settings_role_delete", label: "Delete" },
          ],
        },
        {
          label: "Staff",
          className: "staff",
          checkboxes: [
            // { id: "allStaff", label: "All" },
            { id: "settings_staff_add", label: "Add" },
            { id: "settings_staff_view", label: "View" },
            { id: "settings_staff_edit", label: "Edit" },
            { id: "settings_staff_delete", label: "Delete" },
          ],
        },
        {
          label: "Gmail",
          className: "gmail",
          checkboxes: [
            // { id: "allGmail", label: "All" },
            { id: "viewGmail", label: "View" },
          ],
        },
      ],
    },
  ];

  return (
    <Modal
      className="modal-role custom-role-modal"
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Role</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <Loader />
        ) : (
          <Form>
            <Form.Group controlId="editRoleName">
              <Form.Label>Role Name</Form.Label>
              <Form.Control
                type="text"
                className="form-control-department"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="editRoleDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className="form-control-department"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
            </Form.Group>

            <Form.Group controlId="editAgentType">
              <Form.Label>Staff Type</Form.Label>
              <Form.Control type="text" value={agentName} disabled />
            </Form.Group>
            <div className="container mt-3">
              <div className="row row-permission parent-border1">
                {permissionCategories.map((category) => (
                  <div
                    key={category.label}
                    className={`col-12 col-md-2 parent-border ${category.className}`}
                  >
                    <label className="main-heading-checkbox text-white p-2 d-block">
                      {category.label}
                    </label>

                    {/* Add "All" checkbox for the main category */}
                    {category.checkboxes && (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`all-${category.className}`}
                          onChange={(e) =>
                            toggleAllCheckboxes(e, category.className)
                          } // Trigger the toggleAllCheckboxes function for main category
                          checked={category.checkboxes.every(
                            (checkbox) => checkboxStates[checkbox.id] === 1
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`all-${category.className}`}
                        >
                          All
                        </label>
                      </div>
                    )}

                    {/* Display checkboxes in the main category */}
                    {category.checkboxes &&
                      category.checkboxes.map((checkbox) => (
                        <div className="form-check" key={checkbox.id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={checkboxStates[checkbox.id] === 1}
                            value=""
                            id={checkbox.id}
                            onChange={(e) =>
                              handleCheckboxChange(e, checkbox.id)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={checkbox.id}
                          >
                            {checkbox.label}
                          </label>
                        </div>
                      ))}

                    {/* Subcategories (if any) */}
                    <div className="subcategories-role">
                    {category.subcategories &&
                      category.subcategories.map((subcategory) => (
                        <div
                          key={subcategory.label}
                          className={`col-12 col-md-3 ${subcategory.className}`}
                        >
                          <label className="bg-light p-1">
                            {subcategory.label}
                          </label>

                          {/* Add "All" checkbox for each subcategory */}
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`all-${subcategory.className}`}
                              onChange={(e) =>
                                toggleAllCheckboxes(
                                  e,
                                  category.className,
                                  subcategory.className
                                )
                              }
                              checked={subcategory.checkboxes.every(
                                (checkbox) => checkboxStates[checkbox.id] === 1
                              )}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`all-${subcategory.className}`}
                            >
                              All
                            </label>
                          </div>

                          {/* Display checkboxes within the subcategory */}
                          {subcategory.checkboxes.map((checkbox) => (
                            <div className="form-check" key={checkbox.id}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={checkboxStates[checkbox.id] === 1}
                                value=""
                                id={checkbox.id}
                                onChange={(e) =>
                                  handleCheckboxChange(e, checkbox.id)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={checkbox.id}
                              >
                                {checkbox.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      ))}
                      </div>
                  </div>
                ))}
              </div>
            </div>
          </Form>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn-cancel" onClick={onHide}>
          Close
        </Button>
        <Button className="btn-update" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditRoleModal;
