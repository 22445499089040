import React, { useState, useEffect } from 'react';
import './GraphSection.css';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GraphSection = () => {
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [currentMonthName, setCurrentMonthName] = useState('');
  const [unresolvedTickets, setUnresolvedTickets] = useState([]);
  const [overdueTickets, setOverdueTickets] = useState([]);
  const [dueTodayTickets, setDueTodayTickets] = useState([]);
  const [openTickets, setOpenTickets] = useState([]);
  const [pendingTickets, setPendingTickets] = useState([]);

  useEffect(() => {
    const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

    const fetchData = async () => {
      const token = sessionStorage.getItem('TicketSystemSuperAdminToken');
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();

      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ];
      setCurrentMonthName(monthNames[currentDate.getMonth()]);

      const days = Array.from({ length: getDaysInMonth(currentMonth, currentYear) }, (_, i) => (i + 1).toString());
      setDaysInMonth(days);

      try {
        const response = await fetch('https://ticketsystem.techfluxsolutions.com/dayWiseCounts', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();

        if (data.response) {
          const dayWiseCounts = data.day_wise_counts;

          const unresolved = [];
          const overdue = [];
          const dueToday = [];
          const open = [];
          const pending = [];

          days.forEach(day => {
            const dateKey = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            const counts = dayWiseCounts[dateKey] || {
              open: 0,
              closed: 0,
              unresolved_tickets_count: 0,
              overdue_tickets_count: 0,
              due_today_tickets_count: 0,
              pending_tickets_count: 0,
            };

            unresolved.push(counts.unresolved_tickets_count);
            overdue.push(counts.overdue_tickets_count);
            dueToday.push(counts.due_today_tickets_count);
            open.push(counts.open);
            pending.push(counts.pending_tickets_count);
          });

          setUnresolvedTickets(unresolved);
          setOverdueTickets(overdue);
          setDueTodayTickets(dueToday);
          setOpenTickets(open);
          setPendingTickets(pending);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const data = {
    labels: daysInMonth,
    datasets: [
      {
        label: 'Unresolved Tickets',
        data: unresolvedTickets,
        backgroundColor: '#c0392b',
        borderColor: 'black',
        borderWidth: 1,
      },
      {
        label: 'Overdue Tickets',
        data: overdueTickets,
        backgroundColor: '#f39c12',
        borderColor: 'black',
        borderWidth: 1,
      },
      {
        label: 'Due Today Tickets',
        data: dueTodayTickets,
        backgroundColor: '#12344D',
        borderColor: 'black',
        borderWidth: 1,
      },
      {
        label: 'Open Tickets',
        data: openTickets,
        backgroundColor: '#27ae60',
        borderColor: 'black',
        borderWidth: 1,
      },
      {
        label: 'Pending Tickets',
        data: pendingTickets,
        backgroundColor: '#2980b9',
        borderColor: 'black',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { beginAtZero: true },
      y: { beginAtZero: true },
    },
    plugins: {
      legend: { position: 'bottom' },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
        },
      },
    },
  };

  return (
    <div className="container-graph container-recent-activity" style={{ overflowX: 'auto' }}>
      <h3 style={{ textAlign: 'center', marginBottom: '20px', color: '#12344D' }}>{currentMonthName}</h3>
      <div style={{ width: '2000px', height: '400px' }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default GraphSection;



// import React, { useState, useEffect } from 'react';
// import './GraphSection.css';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const GraphSection = () => {
//   const [daysInMonth, setDaysInMonth] = useState([]);
//   const [currentMonthName, setCurrentMonthName] = useState('');
//   const [unresolvedTickets, setUnresolvedTickets] = useState([]);
//   const [overdueTickets, setOverdueTickets] = useState([]);
//   const [dueTodayTickets, setDueTodayTickets] = useState([]);
//   const [openTickets, setOpenTickets] = useState([]);
//   const [pendingTickets, setPendingTickets] = useState([]);
//   const [chartHeight, setChartHeight] = useState(400); // Default height

//   useEffect(() => {
//     const getDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

//     const fetchData = async () => {
//       const token = sessionStorage.getItem('TicketSystemSuperAdminToken');
//       const currentDate = new Date();
//       const currentMonth = currentDate.getMonth() + 1;
//       const currentYear = currentDate.getFullYear();

//       const monthNames = [
//         'January', 'February', 'March', 'April', 'May', 'June',
//         'July', 'August', 'September', 'October', 'November', 'December',
//       ];
//       setCurrentMonthName(monthNames[currentDate.getMonth()]);

//       const days = Array.from({ length: getDaysInMonth(currentMonth, currentYear) }, (_, i) => (i + 1).toString());
//       setDaysInMonth(days);

//       try {
//         const response = await fetch('https://ticketsystem.techfluxsolutions.com/dayWiseCounts', {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`,
//           },
//         });
//         const data = await response.json();

//         if (data.response) {
//           const dayWiseCounts = data.day_wise_counts;

//           const unresolved = [];
//           const overdue = [];
//           const dueToday = [];
//           const open = [];
//           const pending = [];

//           days.forEach(day => {
//             const dateKey = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
//             const counts = dayWiseCounts[dateKey] || {
//               open: 0,
//               closed: 0,
//               unresolved_tickets_count: 0,
//               overdue_tickets_count: 0,
//               due_today_tickets_count: 0,
//               pending_tickets_count: 0,
//             };

//             unresolved.push(counts.unresolved_tickets_count);
//             overdue.push(counts.overdue_tickets_count);
//             dueToday.push(counts.due_today_tickets_count);
//             open.push(counts.open);
//             pending.push(counts.pending_tickets_count);
//           });

//           setUnresolvedTickets(unresolved);
//           setOverdueTickets(overdue);
//           setDueTodayTickets(dueToday);
//           setOpenTickets(open);
//           setPendingTickets(pending);

//           // Calculate the max value across datasets and adjust chart height
//           const maxValue = Math.max(...unresolved, ...overdue, ...dueToday, ...open, ...pending);
//           setChartHeight(maxValue * 100); // 100px per row
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const data = {
//     labels: daysInMonth,
//     datasets: [
//       {
//         label: 'Unresolved Tickets',
//         data: unresolvedTickets,
//         backgroundColor: '#c0392b',
//         borderColor: 'black',
//         borderWidth: 1,
//       },
//       {
//         label: 'Overdue Tickets',
//         data: overdueTickets,
//         backgroundColor: '#f39c12',
//         borderColor: 'black',
//         borderWidth: 1,
//       },
//       {
//         label: 'Due Today Tickets',
//         data: dueTodayTickets,
//         backgroundColor: '#12344D',
//         borderColor: 'black',
//         borderWidth: 1,
//       },
//       {
//         label: 'Open Tickets',
//         data: openTickets,
//         backgroundColor: '#27ae60',
//         borderColor: 'black',
//         borderWidth: 1,
//       },
//       {
//         label: 'Pending Tickets',
//         data: pendingTickets,
//         backgroundColor: '#2980b9',
//         borderColor: 'black',
//         borderWidth: 1,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       x: { beginAtZero: true },
//       y: {
//         beginAtZero: true,
//         ticks: {
//           stepSize: 1, // Ensure the y-axis increments by 1
//         },
//       },
//     },
//     plugins: {
//       legend: { position: 'bottom' },
//       tooltip: {
//         callbacks: {
//           title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
//           label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
//         },
//       },
//     },
//   };

//   return (
//     <div className="container-graph container-recent-activity" style={{ overflowX: 'auto' }}>
//       <h3 style={{ textAlign: 'center', marginBottom: '20px', color: '#12344D' }}>{currentMonthName}</h3>
//       <div style={{ width: '2000px', height: `${chartHeight}px` }}>
//         <Bar data={data} options={options} />
//       </div>
//     </div>
//   );
// };

// export default GraphSection;
