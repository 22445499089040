
import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';

import axios from 'axios';

import { toast } from 'react-toastify';

import Loader from './../../../Loader/Loader';

const EditDepartmentModal = ({ show, onHide, departmentData ,fetchDepartments}) => {

  const [department, setDepartment] = useState(null);

  const [name, setName] = useState('');

  const [loading, setLoading] = useState(false);

  console.log("modal id",departmentData?.department_id)

  useEffect(() => {

    // Fetch department data when departmentData?.department_id changes

    const fetchDepartmentData = async () => {

      if (!departmentData?.department_id) return;

      setLoading(true);

      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      try {

        const response = await axios.get(

          `https://ticketsystem.techfluxsolutions.com/get_department_by_id/${departmentData?.department_id}`,

          {

            headers: {

              Authorization: `Bearer ${token}`,

            },

          }

        );

        const deptData = response.data;

        console.log("",deptData)

        setDepartment(deptData);

        setName(deptData.data.department_name); // Set initial form value

      } catch (error) {

        // toast.error("Failed to fetch department data.");

      } finally {

        setLoading(false);

      }

    };

    fetchDepartmentData();

  }, [departmentData?.department_id]); // Fetches new data when departmentData?.department_id changes

  // Reset state when the modal closes

  useEffect(() => {

    if (!show) {

      setDepartment(null);

      setName('');

    }

  }, [show]);

  const handleSubmit = async () => {

    setLoading(true);

    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    try {

      const response = await axios.post(

        "https://ticketsystem.techfluxsolutions.com/update_department_new",

        {

          department_id: departmentData?.department_id,

          department_name: name

        },

        {

          headers: {

            Authorization: `Bearer ${token}`,

          },

        }

      );

      console.log("update response",response);

      toast.success("Department updated successfully");

      fetchDepartments()

      onHide();

    } catch (error) {

      toast.error("Failed to update department");

    } finally {

      setLoading(false);

    }

  };

  return (

    <Modal show={show} onHide={onHide} centered>

      <Modal.Header closeButton>

        <Modal.Title>Edit Department</Modal.Title>

      </Modal.Header>

      <Modal.Body>

        {loading ? (

          <Loader />

        ) : (

          <Form>

            <Form.Group>

              <Form.Label>Department Name</Form.Label>

              <Form.Control

                type="text"

                value={name}

                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e)=>{
                  if(e.key==='Enter'){
                    handleSubmit();
                  }
                }}

              />

            </Form.Group>

          </Form>

        )}

      </Modal.Body>

      <Modal.Footer>

        <Button className="btn-cancel" onClick={onHide}>

          Cancel

        </Button>

        <Button className="btn-update" onClick={handleSubmit} disabled={loading}>

          Save Changes

        </Button>

      </Modal.Footer>

    </Modal>

  );

};

export default EditDepartmentModal;

























