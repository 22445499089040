import React, { useState, useEffect } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import CreateRoleModal from "./CreateRolesModal/CreateRolesModal";
import EditRoleModal from "./EditRolesModal/EditRolesModal";
import DeleteRoleModal from "./DeleteRolesModal/DeleteRolesModal";
import { FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Loader/Loader";

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roleIdData, setRoleIdData] = useState([]);
  const [rolePermissions, setRolePermissions] = useState({});

  useEffect(() => {
    // Retrieve role permissions from sessionStorage
    const permissions = sessionStorage.getItem("TicketSystemRolePermissions");
    if (permissions) {
      setRolePermissions(JSON.parse(permissions));
    }
  }, []);

  const handleEditClick = async (roleId) => {
    const response = await axios.get(
      `https://ticketsystem.techfluxsolutions.com/get_roles/${roleId}`,
      {
        headers: {
          Authorization: "Bearer TicketSystemSuperAdminToken",
        },
      }
    );
    console.log("response role id:", response?.data?.roles?.[0]);
    setRoleIdData(response?.data?.roles?.[0]);
  };
  // Fetch roles from API
  const fetchRoles = async () => {
    try {
      const response = await axios.get(
        "https://ticketsystem.techfluxsolutions.com/get_roles",
        {
          headers: {
            Authorization: "Bearer TicketSystemSuperAdminToken",
          },
        }
      );
      console.log("Role Response", response);
      if (response?.status === 200) {
        setRoles(response?.data?.roles || []);
      } else {
        toast.error("Failed to load roles");
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      toast.error("An error occurred while loading roles.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  // Generate a unique ID for each new role
  const generateId = () =>
    roles.length ? Math.max(...roles.map((r) => r.id)) + 1 : 1;
  const handleCreate = (newRole) => {
    setRoles([...roles, { ...newRole, id: generateId() }]);
    setShowCreateModal(false);
    toast.success("Role successfully created!");
  };

  const handleEdit = async (role) => {
    if (!role || !role.role_id) {
      console.log("currentRole is either null or does not have a role_id.");
      toast.error("Role ID is undefined.");
      return;
    }

    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
    // Ensure the token exists
    if (!token) {
      toast.error("Authorization token is missing. Please log in again.");
      return;
    }
    setLoading(true);
    try {
      // Fetch department data
      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/get_roles/${role.role_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const roleData = response.data;
      setCurrentRole(roleData);
      setShowEditModal(true);
    } catch (error) {
      toast.error("Failed to fetch Role data.");
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async () => {
    if (!currentRole || !currentRole.role_id) {
      toast.error("Role ID is undefined.");
      return;
    }
    try {
      const response = await axios.delete(
        `https://ticketsystem.techfluxsolutions.com/delete_roles/${currentRole.role_id}`,
        {
          headers: {
            Authorization: "Bearer TicketSystemSuperAdminToken",
          },
        }
      );
      if (response.status === 200) {
        setRoles(roles.filter((role) => role.role_id !== currentRole.role_id)); // Change to agent_type_id to match your API
        setShowDeleteModal(false);
        toast.success("Role successfully deleted!");
        fetchRoles();
      } else {
        toast.error("Failed to delete role!");
      }
    } catch (error) {
      console.error("Error deleting role:", error);
      toast.error("An error occurred while deleting the role.");
    }
  };
  const renderTooltip = (message) => <Tooltip>{message}</Tooltip>;
  return (
    (rolePermissions.settings_role_view === 1 || 
      rolePermissions.settings_role_delete === 1 || 
      rolePermissions.settings_role_edit === 1 || 
      rolePermissions.settings_role_add === 1) ? (
    <div className="container ">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Roles</h2>

        {rolePermissions.settings_role_add === 1 && (
          <button
            className="btn btn-create-dept"
            onClick={() => setShowCreateModal(true)}
          >
            + New Role
          </button>
        )}
      </div>
      {/* Loading with Custom Loader */}
      {loading ? (
        <div className="text-center my-3">
          <Loader /> {/* Use the custom loader component here */}
        </div>
      ) : (
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Role Name</th>
              <th>Description</th>
              <th>Staff Type</th>
              {(rolePermissions.settings_role_delete === 1 ||
                rolePermissions.settings_role_edit === 1) && <th>Action</th>}
            </tr>
          </thead>
          {/* <tbody>
            
            {roles.map((role, index) => (
              <tr key={role.id}>
                <td>{index + 1}</td>
                <td>{role.role_name}</td>
                <td>{role.role_description}</td>
                <td>{role.agent_type_name}</td>
                {(rolePermissions.settings_role_delete === 1 ||
                rolePermissions.settings_role_edit === 1) &&
                <td>
                  <div className="icons-wrapper">
                    {rolePermissions.settings_role_edit === 1 && (
                      <OverlayTrigger
                        placement="left"
                        overlay={renderTooltip("Edit")}
                      >
                        <span>
                          <FaEdit
                            className="edit-icon-roles"
                            onClick={() => {
                              handleEditClick(role.role_id);
                              console.log("Role", role.id);
                              setCurrentRole(role);
                              setShowEditModal(true);
                            }}
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                    {rolePermissions.settings_role_delete === 1 && (
                      <OverlayTrigger
                        placement="left"
                        overlay={renderTooltip("Delete")}
                      >
                        <span>
                          <FaTrash
                            className="trash-icon-roles"
                            onClick={() => {
                              console.log("Role", role.id);
                              setCurrentRole(role); // Set the entire role object
                              setShowDeleteModal(true);
                            }}
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>
                </td>
}
              </tr>
            ))}
          </tbody> */}

<tbody>
  {roles.length === 0 ? (
    <tr>
      <td colSpan="5" className="text-center">No role available</td>
    </tr>
  ) : (
    roles.map((role, index) => (
      <tr key={role.id}>
        <td>{index + 1}</td>
        <td>{role.role_name}</td>
        <td>{role.role_description}</td>
        <td>{role.agent_type_name}</td>
        {(rolePermissions.settings_role_delete === 1 ||
          rolePermissions.settings_role_edit === 1) && (
          <td>
            <div className="icons-wrapper">
              {rolePermissions.settings_role_edit === 1 && (
                <OverlayTrigger
                  placement="left"
                  overlay={renderTooltip("Edit")}
                >
                  <span>
                    <FaEdit
                      className="edit-icon-roles"
                      onClick={() => {
                        handleEditClick(role.role_id);
                        console.log("Role", role.id);
                        setCurrentRole(role);
                        setShowEditModal(true);
                      }}
                    />
                  </span>
                </OverlayTrigger>
              )}
              {rolePermissions.settings_role_delete === 1 && (
                <OverlayTrigger
                  placement="left"
                  overlay={renderTooltip("Delete")}
                >
                  <span>
                    <FaTrash
                      className="trash-icon-roles"
                      onClick={() => {
                        console.log("Role", role.id);
                        setCurrentRole(role);
                        setShowDeleteModal(true);
                      }}
                    />
                  </span>
                </OverlayTrigger>
              )}
            </div>
          </td>
        )}
      </tr>
    ))
  )}
</tbody>

        </table>
      )}
      {/* Create Role Modal */}
      <CreateRoleModal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        onCreate={handleCreate}
        fetchRoles={fetchRoles}
        agentTypes={["Full-time", "Part-time", "Contract"]} // Pass agent types here
      />
      {/* Edit Role Modal */}
      <EditRoleModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        role={currentRole}
        fetchRoles={fetchRoles}
        roleIdData={roleIdData}
      />
      {/* Delete Role Modal */}
      <DeleteRoleModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        role={currentRole}
        onDelete={handleDelete}
        fetchRoles={fetchRoles}
      />
      {/* Toast Container */}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnHover
      />
    </div>
      ) : null
  );
};
export default Roles;
