
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Mail_fromListView.css";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";

const Mail_fromListViewReply = ({ ticketId, newReplies }) => {
  const [threadData, setThreadData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
      if (!token) {
        throw new Error("No authentication token found in session storage.");
      }

      // setLoading(true);
      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/getRepliesByTicketId/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { ticket_email, ticket_message, replies } = response.data.data;

      const formattedReplies = [
        {
          message: ticket_message,
          email: ticket_email,
          replies: [],
        },
        ...(replies
          ? Object.values(replies).map((reply) => ({
              sender: reply.ticket_reply_cc.join(", "),
              email: reply.ticket_reply_bcc.join(", "),
              message: reply.ticket_reply_message,
              ticket_email,
              replies: [],
            }))
          : []),
      ];

      setThreadData(formattedReplies);
    } catch (err) {
      toast.warning("There is no conversation.");
      setThreadData([
        {
          message: "No conversation found.",
          email: "",
          sender: "",
          replies: [],
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [newReplies, ticketId]);

  if (loading) {
    return <Loader />;
  }

  const renderThread = (thread) => {
    if (!Array.isArray(thread)) {
      return <div>Invalid thread data</div>;
    }

    return thread.map((email, index) => (
      <div key={index} className="mail-thread-item mb-5">
        <div className="mail-thread-header">
          <span className="mail-thread-email">{email.ticket_email}</span>
          <span className="mail-thread-sender"> {email.sender}</span>
          <span className="mail-thread-address">&lt;{email.email}&gt;</span>
        </div>
        <div
          className="mail-thread-message"
          dangerouslySetInnerHTML={{ __html: email.message }}
        ></div>
        {email.replies.length > 0 && (
          <div className="mail-thread-replies">{renderThread(email.replies)}</div>
        )}
      </div>
    ));
  };

  return (
    <div className="mail-thread-container">
      {threadData.length > 0 ? (
        renderThread(threadData)
      ) : (
        <div className="mail-thread-message">
          <p dangerouslySetInnerHTML={{ __html: "No messages available." }}></p>
        </div>
      )}
    </div>
  );
};

export default Mail_fromListViewReply;
