
import React, { useEffect, useState } from 'react';
import './HeroDashboardSection.css';

const HeroDashboardSection = () => {
  const token = sessionStorage.getItem('TicketSystemSuperAdminToken');

  // Define the API endpoints
  const apiEndpoints = {
    unresolved: 'https://ticketsystem.techfluxsolutions.com/unresolved_tickets',
    overdue: 'https://ticketsystem.techfluxsolutions.com/overdue_tickets',
    dueToday: 'https://ticketsystem.techfluxsolutions.com/due_today_tickets',
    open: 'https://ticketsystem.techfluxsolutions.com/agents/open_tickets',
    pending: 'https://ticketsystem.techfluxsolutions.com/agents/pending_tickets',
  };

  const [counts, setCounts] = useState({
    unresolved: 0,
    overdue: 0,
    dueToday: 0,
    open: 0,
    pending: 0,
  });

  const fetchCounts = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    const results = {};
    for (const [key, url] of Object.entries(apiEndpoints)) {
      try {
        const response = await fetch(url, { headers });
        const data = await response.json();
        results[key] = data.count || 0; // Default to 0 if `count` is not available
      } catch (error) {
        console.error(`Failed to fetch data for ${key}:`, error);
        results[key] = 0; // Default to 0 in case of error
      }
    }
    setCounts(results);
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  return (
    <div className='containerHeroDashboard p-3 mb-4 mt-5'>
      <div className='row Main_TotalMenu_HeyMaria mt-4'>
        {/* Item 1 */}
        <div className='col-12 col-md-2 menu-Item-Graph'>
          <div className='container TotalMenu_HeyMaria'>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <div className='container mt-2 SubMenu_HeyMaria'>
                  <div className='col-12 col-md-8 label_HeyMaria'>Unresolved</div>
                  <div className='col-12 col-md-4 value_HeyMaria'>{counts.unresolved}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Item 2 */}
        <div className='col-12 col-md-2 menu-Item-Graph'>
          <div className='container TotalMenu_HeyMaria'>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <div className='container mt-2 SubMenu_HeyMaria'>
                  <div className='col-12 col-md-8 label_HeyMaria'>Overdue</div>
                  <div className='col-12 col-md-4 value_HeyMaria'>{counts.overdue}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Item 3 */}
        <div className='col-12 col-md-2 menu-Item-Graph'>
          <div className='container TotalMenu_HeyMaria'>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <div className='container mt-2 SubMenu_HeyMaria'>
                  <div className='col-12 col-md-8 label_HeyMaria'>Due Today</div>
                  <div className='col-12 col-md-4 value_HeyMaria'>{counts.dueToday}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Item 4 */}
        <div className='col-12 col-md-2 menu-Item-Graph'>
          <div className='container TotalMenu_HeyMaria'>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <div className='container mt-2 SubMenu_HeyMaria'>
                  <div className='col-12 col-md-8 label_HeyMaria'>Open</div>
                  <div className='col-12 col-md-4 value_HeyMaria'>{counts.open}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Item 5 */}
        <div className='col-12 col-md-2 menu-Item-Graph'>
          <div className='container TotalMenu_HeyMaria'>
            <div className='row'>
              <div className='col-12 col-md-12'>
                <div className='container mt-2 SubMenu_HeyMaria'>
                  <div className='col-12 col-md-8 label_HeyMaria'>Pending</div>
                  <div className='col-12 col-md-4 value_HeyMaria'>{counts.pending}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroDashboardSection;

