
import React, { useState, useEffect } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import CreateDepartmentModal from "./CreateDepartmentModal/CreateDepartmentModal";
import EditDepartmentModal from "./EditDepartmentModal/EditDepartmentModal";
import DeleteDepartmentModal from "./DeleteDepartmentModal/DeleteDepartmentModal";
// import ViewAgentsModal from "./ViewAgentsModal/ViewAgentsModal";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import "./Department.css";
import Loader from "../../Loader/Loader";
const Dashboard = () => {
 const [departments, setDepartments] = useState([]);
 const [currentDepartment, setCurrentDepartment] = useState(null);
 const [showCreateModal, setShowCreateModal] = useState(false);
 const [showEditModal, setShowEditModal] = useState(false);
 const [showDeleteModal, setShowDeleteModal] = useState(false);
 const [showViewAgentsModal, setShowViewAgentsModal] = useState(false);
 const [loading, setLoading] = useState(true);
 const [departmentId,setDepartmentId]=useState();
 const [rolePermissions, setRolePermissions] = useState({});
 useEffect(() => {
   // Retrieve role permissions from sessionStorage
   const permissions = sessionStorage.getItem("TicketSystemRolePermissions");
   if (permissions) {
     setRolePermissions(JSON.parse(permissions));
   }
 }, []);
 const fetchDepartments = async () => {
  setLoading(true);
  try {
   const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
   const response = await axios.get("https://ticketsystem.techfluxsolutions.com/get_departments_new", {
    headers: {
     Authorization: `Bearer ${token}`
    }
   });
   if (Array.isArray(response?.data?.data)) {
    setDepartments(response?.data?.data);
   } else {
    setDepartments([]);
    toast.error("Unexpected response format");
   }
  } catch (error) {
   toast.error("Failed to load departments");
  } finally {
   setLoading(false);
  }
 };
 useEffect(() => {
  fetchDepartments();
 }, []);
 const handleCreate = (newDepartment) => {
  setDepartments([...departments, newDepartment]);
  setShowCreateModal(false);
 };
 const handleEdit = async (dept) => {
  if (!dept || !dept.department_id) {
    console.log("currentDepartment is either null or does not have a department_id.");
    toast.error("Department ID is undefined.");
    return;
  }
  const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
  // Ensure the token exists
  if (!token) {
    toast.error("Authorization token is missing. Please log in again.");
    return;
  }
  setLoading(true);
  try {
    // Fetch department data
    const response = await axios.get(
      `https://ticketsystem.techfluxsolutions.com/get_department_by_id/${dept.department_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const deptData = response.data;
    setDepartmentId(deptData);
    setShowEditModal(true);
  } catch (error) {
    toast.error("Failed to fetch department data.");
  } finally {
    setLoading(false);
  }
};
 // Debugging and tracking currentDepartment changes
useEffect(() => {
  console.log("Updated currentDepartment:", currentDepartment);
}, [currentDepartment]);
 const handleDelete = async () => {
  console.log("Current Dept", currentDepartment.department_id);
  if (!currentDepartment || !currentDepartment.department_id) {
   toast.error('Department ID is undefined.');
   return;
  }
  try {
   const token = sessionStorage.getItem("TicketSystemSuperAdminToken"); // Retrieve the token
   const response = await axios.delete(`https://ticketsystem.techfluxsolutions.com/delete_department/${currentDepartment.department_id}`, {
    headers: {
     'Authorization': `Bearer ${token}`, // Use the retrieved token
    },
   });
   if (response.status === 200) {
    setDepartments(departments.filter((department) => department.department_id !== currentDepartment.department_id));
    setShowDeleteModal(false);
    toast.success('Department successfully deleted!'); // Update the message to reflect 'Department'
    fetchDepartments(); // Refresh the department list
   } else {
    toast.error('Failed to delete department!');
   }
  } catch (error) {
   console.error('Error deleting department:', error);
   toast.error('An error occurred while deleting the department.');
  }
 };
 const renderTooltip = (message) => (
  <Tooltip id="button-tooltip">{message}</Tooltip>
 );
 return (
  (rolePermissions.settings_department_view === 1 || 
    rolePermissions.settings_department_delete === 1 || 
    rolePermissions.settings_department_edit === 1 || 
    rolePermissions.settings_department_add === 1) ? (
  <div className="container">
   <ToastContainer />
   {loading ? (
    <Loader />
   ) : (
    <>
     <div className="d-flex justify-content-between align-items-center mb-3">
      <h2>Departments</h2>
      {rolePermissions.settings_department_add === 1 && (
      <button
       className="btn btn-create-dept"
       onClick={() => setShowCreateModal(true)}
      >
       + Create Department
      </button>
      )}
     </div>
     <table className="table table-bordered text-center">
      <thead>
       <tr>
        <th>Sr No.</th>
        <th>Department</th>
        {(rolePermissions.settings_department_delete === 1 ||
                rolePermissions.settings_department_edit === 1) &&
        <th>Actions</th>
}
       </tr>
      </thead>
      {/* <tbody>
       {departments.map((dept, index) => (
        <tr key={dept.id}>
         <td>{index + 1}</td>
         <td>{dept.department_name}</td>
         {(rolePermissions.settings_department_delete === 1 ||
                rolePermissions.settings_department_edit === 1) &&
         <td>
          <div className="icons-wrapper">
          {rolePermissions.settings_department_edit === 1 && (
           <OverlayTrigger
            placement="left"
            overlay={<Tooltip id={`tooltip-edit-${dept.id}`}>Edit</Tooltip>}
           >
            <span>
             <FaEdit
              className="icon"
              onClick={() =>{
               setCurrentDepartment(dept);
               handleEdit(dept)
              } }
             />
            </span>
           </OverlayTrigger>
          )}

{rolePermissions.settings_department_delete === 1 && (
           <OverlayTrigger
            placement="left"
            overlay={<Tooltip id={`tooltip-delete-${dept.id}`}>Delete</Tooltip>}
           >
            <span>
             <FaTrash
              className="icon"
              onClick={() => {
               setCurrentDepartment(dept);
               setShowDeleteModal(true);
              }}
             />
            </span>
           </OverlayTrigger>
)}
          </div>
         </td>
}
        </tr>
       ))}
      </tbody> */}

<tbody>
  {departments.length > 0 ? (
    departments.map((dept, index) => (
      <tr key={dept.id}>
        <td>{index + 1}</td>
        <td>{dept.department_name}</td>
        {(rolePermissions.settings_department_delete === 1 ||
          rolePermissions.settings_department_edit === 1) && (
          <td>
            <div className="icons-wrapper">
              {rolePermissions.settings_department_edit === 1 && (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id={`tooltip-edit-${dept.id}`}>Edit</Tooltip>}
                >
                  <span>
                    <FaEdit
                      className="icon"
                      onClick={() => {
                        setCurrentDepartment(dept);
                        handleEdit(dept);
                      }}
                    />
                  </span>
                </OverlayTrigger>
              )}

              {rolePermissions.settings_department_delete === 1 && (
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id={`tooltip-delete-${dept.id}`}>Delete</Tooltip>}
                >
                  <span>
                    <FaTrash
                      className="icon"
                      onClick={() => {
                        setCurrentDepartment(dept);
                        setShowDeleteModal(true);
                      }}
                    />
                  </span>
                </OverlayTrigger>
              )}
            </div>
          </td>
        )}
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={rolePermissions.settings_department_delete === 1 || rolePermissions.settings_department_edit === 1 ? 3 : 2}>
        No Department Available
      </td>
    </tr>
  )}
</tbody>

     </table>
     <CreateDepartmentModal
      show={showCreateModal}
      onHide={() => setShowCreateModal(false)}
      onCreate={handleCreate}
      fetchDepartments ={fetchDepartments}
     />
     <EditDepartmentModal
      show={showEditModal}
      onHide={() => setShowEditModal(false)}
      departmentData={currentDepartment}
      onEdit={handleEdit}
      fetchDepartments ={fetchDepartments }
      // departmentId ={currentDepartment}
     />
     <DeleteDepartmentModal
      show={showDeleteModal}
      onHide={() => setShowDeleteModal(false)}
      department={currentDepartment}
      onDelete={handleDelete}
     />
     {/* <ViewAgentsModal
      show={showViewAgentsModal}
      onHide={() => setShowViewAgentsModal(false)}
      department={currentDepartment}
     /> */}
    </>
   )}
  </div>
   ) : null 
 );
};

export default Dashboard;






















































































































