import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Mail_fromListView.css";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";

const Mail_fromListViewForward = ({ ticketId, newReplies }) => {
  const [threadData, setThreadData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchForwardData = async () => {
    try {
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

      if (!token) {
        throw new Error("No authentication token found in session storage.");
      }
      setLoading(true);

      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/agents/getForwardByTicketId/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);

      console.log("API Response:", response.data); // Log the response data

      // Extract the forward data and format it
      const forward = response.data.data.forward;
      const formattedReplies = Object.values(forward).map((forwardItem) => ({
        test_email:forwardItem.ticket_forward_to,
        sender: forwardItem.ticket_forward_cc.join(", "), // Join CC addresses to display
        email: forwardItem.ticket_forward_bcc.join(", "), // Join BCC addresses to display
        message: forwardItem.ticket_forward_message,
        replies: [], // Placeholder for nested replies if needed
      }));

      // Set the thread data
      setThreadData(formattedReplies);
    } catch (err) {
      // setError(err.message);
      toast.warning("There is no conversation..");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForwardData();
  }, [ticketId]);

  useEffect(() => {
    setThreadData(newReplies);
  }, [newReplies]);

  const renderThread = (thread) => {
    if (!Array.isArray(thread)) {
      return <div>Invalid thread data</div>;
    }

    return thread.map((email, index) => (
      <div key={index} className="mail-thread-item mb-5">
        <div className="mail-thread-header">
        <span className="mail-thread-sender">{email.test_email}</span>
          <span className="mail-thread-sender">{email.sender}</span>
          <span className="mail-thread-address">&lt;{email.email}&gt;</span>
          {/* You can add a date field if the API response provides it */}
          {/* <span className="mail-thread-date">{email.date}</span> */}
        </div>
        <div
          className="mail-thread-message"
          dangerouslySetInnerHTML={{ __html: email.message }}
        ></div>
        {email.replies.length > 0 && (
          <div className="mail-thread-replies">
            {renderThread(email.replies)}
          </div>
        )}
      </div>
    ));
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return <div className="mail-thread-container">{renderThread(threadData)}</div>;
};

export default Mail_fromListViewForward;
