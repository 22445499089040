// import React, { useState, useEffect } from "react";
// import "../AddCollaboratorModal/AddCollaboratorModal.css";
// import axios from "axios";
// import { toast } from "react-toastify"; // Import toast

// const AddCollaboratorModal = ({ show, onClose, onSave, ticketId,fetchData }) => {
//   const [selectedAgent, setSelectedAgent] = useState("");
//   const [collaborators, setCollaborators] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     if (show) {
//       fetchCollaborators();
//     }
//   }, [show]);

//   const fetchCollaborators = async () => {
//     setLoading(true);
//     setError(null);
//     const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

//     try {
//       const response = await axios.get(
//         `https://ticketsystem.techfluxsolutions.com/users/getFilteredCollaboratorsList/${ticketId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.data.response) {
//         setCollaborators(response.data.collaborators);
//       } else {
//         setError("Failed to retrieve collaborators.");
//       }
//     } catch (err) {
//       setError(err.message || "An unexpected error occurred.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSave = async () => {
//     if (!selectedAgent) {
//       toast.error("Please select an agent.");
//       return;
//     }

//     const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

//     try {
//       const response = await axios.post(
//         "https://ticketsystem.techfluxsolutions.com/users/add_collaborators",
//         {
//           ticket_id: ticketId,
//           agent_ids: [parseInt(selectedAgent)],
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       if (response.data.response) {
//         onSave(response.data.addedAgents); // Pass added agents to parent component
//         onClose();
//         toast.success("Collaborator added successfully!");
//         fetchData();
//       } else {
//         toast.error("Failed to add collaborator.");
//       }
//     } catch (err) {
//       toast.error(err.message || "An error occurred while adding the collaborator.");
//     }
//   };

//   return (
//     <div className={`modal ${show ? "d-block" : "d-none"}`} tabIndex="-1">
//       <div className="modal-dialog modal-dialog-centered">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h5 className="modal-title">Add Collaborator</h5>
//             <button type="button" className="btn-close" onClick={onClose}></button>
//           </div>
//           <div className="modal-body">
//             {loading ? (
//               <p>Loading collaborators...</p>
//             ) : error ? (
//               <p className="text-danger">{error}</p>
//             ) : (
//               <div className="mb-3">
//                 <label htmlFor="agentDropdown" className="form-label">
//                   Select Agent
//                 </label>
//                 <select
//                   id="agentDropdown"
//                   className="form-select"
//                   value={selectedAgent}
//                   onChange={(e) => setSelectedAgent(e.target.value)}
//                 >
//                   <option value="">-- Select an Agent --</option>
//                   {collaborators.map((agent) => (
//                     <option key={agent.agent_id} value={agent.agent_id}>
//                       {agent.agent_name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             )}
//           </div>
//           <div className="modal-footer">
//             <button type="button" className="btn-cancel" onClick={onClose}>
//               Cancel
//             </button>
//             <button
//               type="button"
//               className="btn-update"
//               onClick={handleSave}
//               disabled={loading || error}
//             >
//               Save
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddCollaboratorModal;



import React, { useState, useEffect } from "react";
import "../AddCollaboratorModal/AddCollaboratorModal.css";
import axios from "axios";
import { toast } from "react-toastify"; // Import toast

const AddCollaboratorModal = ({ show, onClose, onSave, ticketId, fetchData }) => {
  const [selectedAgent, setSelectedAgent] = useState("");
  const [collaborators, setCollaborators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (show) {
      fetchCollaborators();
    }
  }, [show]);

  const fetchCollaborators = async () => {
    setLoading(true);
    setError(null);
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    try {
      const response = await axios.get(
        `https://ticketsystem.techfluxsolutions.com/users/getFilteredCollaboratorsList/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.response) {
        setCollaborators(response.data.collaborators);
      } else {
        setError("Failed to retrieve collaborators.");
      }
    } catch (err) {
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!selectedAgent) {
      toast.error("Please select an agent.");
      return;
    }

    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    try {
      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/users/add_collaborators",
        {
          ticket_id: ticketId,
          agent_ids: [parseInt(selectedAgent)],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.response) {
        onSave(response.data.addedAgents); // Pass added agents to parent component
        handleCancel(); // Clear input after saving
        toast.success("Collaborators added successfully! and emails sent");
        fetchData();
      } else {
        toast.error("Failed to add collaborator.");
      }
    } catch (err) {
      toast.error(err.message || "An error occurred while adding the collaborator.");
    }
  };

  const handleCancel = () => {
    setSelectedAgent(""); // Clear selected input
    onClose(); // Close modal
  };

  return (
    <div className={`modal ${show ? "d-block" : "d-none"}`} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Collaborator</h5>
            <button type="button" className="btn-close" onClick={handleCancel}></button>
          </div>
          <div className="modal-body">
            {loading ? (
              <p>Loading collaborators...</p>
            ) : error ? (
              <p className="text-danger">{error}</p>
            ) : (
              <div className="mb-3">
                <label htmlFor="agentDropdown" className="form-label">
                  Select Agent
                </label>
                <select
                  id="agentDropdown"
                  className="form-select"
                  value={selectedAgent}
                  onChange={(e) => setSelectedAgent(e.target.value)}
                >
                  <option value="">-- Select an Agent --</option>
                  {collaborators.map((agent) => (
                    <option key={agent.agent_id} value={agent.agent_id}>
                      {agent.agent_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn-cancel" onClick={handleCancel}>
              Cancel
            </button>
            <button
              type="button"
              className="btn-update"
              onClick={handleSave}
              disabled={loading || error}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCollaboratorModal;

