// import React, { useState } from 'react';
// import { Modal } from 'react-bootstrap';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Loader from '../../Loader/Loader'; // Adjust the path if needed
// import './../../Credentials/Credentials.css';

// const ForgotPasswordModal = ({ show, handleClose }) => {
//     const [email, setEmail] = useState('');
//     const [loading, setLoading] = useState(false);

//     const handleSave = async () => {
//         if (!email) {
//             toast.error("Please insert email");
//             return;
//         }

//         setLoading(true);

//         try {
//             const response = await fetch("https://ticketsystem.techfluxsolutions.com/agents/forgetPassword", {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify({ agent_email: email }),
//             });

//             const data = await response.json();
            
//             if (response.ok) {
//                 toast.success("Password reset link sent to email!");
//                 handleClose();
//             } else {
//                 toast.error(data.message || "Failed to send password reset link.");
//             }
//         } catch (error) {
//             toast.error("An error occurred. Please try again.");
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <>
//             <ToastContainer />
//             <Modal show={show} onHide={handleClose} centered>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Reset Password</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {loading && <Loader />}
//                     <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
//                         <div className="mb-3 position-relative">
//                             <label htmlFor="email" className="form-label form-label-color">Email</label>
//                             <input
//                                 type="email"
//                                 className="form-control form-credentials"
//                                 id="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                             />
//                         </div>
//                     </form>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <button
//                         type="button"
//                         className="btn btn-credentials"
//                         onClick={handleSave}
//                         disabled={loading}
//                     >
//                         Confirm
//                     </button>
//                 </Modal.Footer>
//             </Modal>
//         </>
//     );
// };

// export default ForgotPasswordModal;


import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../Loader/Loader'; // Adjust the path if needed
import './../../Credentials/Credentials.css';

const ForgotPasswordModal = ({ show, handleClose }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        if (!email) {
            toast.error("Please insert email");
            return;
        }

        setLoading(true);

        try {
            const response = await fetch("https://ticketsystem.techfluxsolutions.com/agents/forgetPassword", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ agent_email: email }),
            });

            const data = await response.json();
            
            if (response.ok) {
                toast.success("Password reset link sent to email!");
                handleCloseModal();
            } else {
                toast.error(data.message || "Failed to send password reset link.");
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setEmail(''); // Clear the input field
        handleClose(); // Call the parent-provided close function
    };

    return (
        <>
            <ToastContainer />
            <Modal show={show} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && <Loader />}
                    <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                        <div className="mb-3 position-relative">
                            <label htmlFor="email" className="form-label form-label-color">Email</label>
                            <input
                                type="email"
                                className="form-control form-credentials"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-credentials"
                        onClick={handleSave}
                        disabled={loading}
                    >
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ForgotPasswordModal;
