

import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Loader/Loader";

const AgentCreateModal = ({
  show,
  onHide,
  onCreate,
  fetchAgents,
  roles = ["Admin", "Support", "Manager"],
  departments = ["Sales", "Tech", "HR", "Customer Service"],
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [departmentsData, setDepartmentsData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setName("");
    setEmail("");
    setSelectedDepartments([]);
    setSelectedRole(null);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    if (!token) {
      toast.error("Authorization token is missing.");
      console.error("Token not found in session storage.");
      return;
    }

    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://ticketsystem.techfluxsolutions.com/get_departments_new",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Fetch error:", response.status, errorText);
          throw new Error(`Error fetching departments: ${response.status}`);
        }

        const data = await response.json();

        if (Array.isArray(data.data)) {
          setDepartmentsData(
            data.data.map((dept) => ({
              value: dept.department_id,
              label: dept.department_name,
            }))
          );
        } else {
          toast.error("Unexpected response structure for departments.");
          console.error("Unexpected response structure:", data);
        }
      } catch (error) {
        toast.error("Error fetching departments.");
        console.error("Error fetching departments:", error.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchRoles = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://ticketsystem.techfluxsolutions.com/get_roles",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Fetch error:", response.status, errorText);
          throw new Error(`Error fetching roles: ${response.status} - ${errorText}`);
        }

        const data = await response.json();
        console.log("Fetched roles:", data.roles);

        if (Array.isArray(data.roles)) {
          setRolesData(
            data.roles.map((role) => ({
              value: role.role_id,
              label: role.role_name,
            }))
          );
        } else if (Array.isArray(data.data)) {
          setRolesData(
            data.data.map((role) => ({
              value: role.role_id,
              label: role.role_name,
            }))
          );
        } else {
          toast.error("Unexpected response structure for roles.");
          console.error("Unexpected response structure:", data);
        }
      } catch (error) {
        toast.error("Error fetching roles.");
        console.error("Error fetching roles:", error.message);
      } finally {
        setLoading(false);
      }
    };

    if (show) {
      fetchDepartments();
      fetchRoles();
    }

    if (!show) {
      resetForm();
    }
  }, [show]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!name || !email || !selectedDepartments.length || !selectedRole) {
      toast.error("All fields are required.");
      return;
    }

    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const agent = {
      agent_name: name,
      agent_email: email,
      selected_department_ids: selectedDepartments.map((d) => d.value),
      role_id: selectedRole.value,
    };

    await registerAgent(agent);
  };

  // const registerAgent = async (agent) => {
  //   const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
  //   setLoading(true);
  
  //   try {
  //     const response = await fetch(
  //       "https://ticketsystem.techfluxsolutions.com/agents/register",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify(agent),
  //       }
  //     );
  
  //     if (!response.ok) {
  //       const errorText = await response.text();
  //       console.error("Registration error:", response.status, errorText);
  //       throw new Error(`Error registering agent: ${response.status} - ${errorText}`);
  //     }
  
  //     const result = await response.json();
  
  //     // Display toast success message with agent's name or a fallback message
  //     const agentName = result.agent_name || "staff";
  //     toast.success(`${agentName} registered successfully.`);
  
  //     // Perform additional actions
  //     fetchAgents(); // Refresh the agents list
  //     console.log("Registered staff:", result);
  //     resetForm();   // Reset the form after successful registration
  //     onHide();      // Close the modal or dialog
  //   } catch (error) {
  //     // Display error toast message
  //     toast.error("Error registering staff.");
  //     console.error("Error registering staff:", error.message);
  //   } finally {
  //     // Stop loading spinner
  //     setLoading(false);
  //   }
  // };
  
  const registerAgent = async (agent) => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
    setLoading(true);
  
    try {
      const response = await fetch(
        "https://ticketsystem.techfluxsolutions.com/agents/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(agent),
        }
      );
  
      const data = await response.json(); // Parse the response JSON
  
      // Check if the response indicates success or failure
      if (data.response === false) {
        // Display error toast if response is false
        const errorMessage = data.error_msg || "Error registering staff.";
        toast.error(errorMessage);
        console.error("Registration error:", data);
        return; // Stop further execution
      }
  
      // If the response is successful
      toast.success(`${data.agent_name || "Staff"} registered successfully.`);
      fetchAgents(); // Refresh agents
      resetForm(); // Reset form fields
      onHide(); // Close modal
    } catch (error) {
      // Handle network or unexpected errors
      toast.error("Unexpected error occurred. Please try again.");
      console.error("Unexpected error:", error.message);
    } finally {
      setLoading(false);
    }
  };
  
  
  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create New Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Loader />
          ) : (
            <Form>
              <Form.Group controlId="name" className="">
                <Form.Label>Name<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter staff's name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevents default form submission
                      handleSubmit();
                    }
                  }}
                />
              </Form.Group>

              <Form.Group controlId="email" className="">
                <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevents default form submission
                      handleSubmit();
                    }
                  }}
                />
              </Form.Group>

              <Form.Group controlId="departments" className="">
                <Form.Label>Departments<span className="text-danger">*</span></Form.Label>
                <Select
                  isMulti
                  options={departmentsData || []}
                  value={selectedDepartments}
                  onChange={setSelectedDepartments}
                  placeholder="Select departments"
                  required
                  isLoading={loading}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevents default form submission
                      handleSubmit();
                    }
                  }}
                />
              </Form.Group>

              <Form.Group controlId="role" className="">
                <Form.Label>Role<span className="text-danger">*</span></Form.Label>
                <Select
                  options={
                    rolesData.length > 0
                      ? rolesData
                      : roles.map((r) => ({ value: r, label: r }))
                  }
                  value={selectedRole}
                  onChange={setSelectedRole}
                  required
                  placeholder="Select role"
                  isLoading={loading}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevents default form submission
                      handleSubmit();
                    }
                  }}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button className="btn-update" onClick={handleSubmit}>
            Create Staff
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AgentCreateModal;
