import React, { useState, useEffect } from 'react';
import './Profile.css'; 
import EditProfile from './EditProfile/EditProfile'; 
import axios from 'axios';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { FaUserCircle } from 'react-icons/fa'; 
import Loader from '../../Loader/Loader';

const Profile = () => {
  const [userData, setUserData] = useState({
    name: 'sohel j',
    email: 'sohel@techflux.in',
    avatar: 'S', 
  });
  const [isEditing, setIsEditing] = useState(false); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const [toastShown, setToastShown] = useState(false); 

  
  const fetchUserProfile = async () => {
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

    if (token) {
      try {
        const response = await axios.get('https://ticketsystem.techfluxsolutions.com/agents/getProfile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success_msg === "Data Retrived Successfully" && response.data.response) {
          setUserData({
            name: response.data.data.agent_name,
            email: response.data.data.agent_email,
            avatar: response.data.data.agent_name.charAt(0).toUpperCase(),
          });

          
          if (!toastShown) {
            // toast.success('Profile fetched successfully!');
            setToastShown(true);
          }
        } else {
          setError('Failed to fetch user data');
          toast.error('Failed to fetch user data');
        }
      } catch (error) {
        setError('An error occurred while fetching user data');
        toast.error('An error occurred while fetching user data');
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      setError('No token found');
      toast.error('No token found');
      setLoading(false);
    }
  };

  
  useEffect(() => {
    fetchUserProfile();
  }, []);

  
  const handleEdit = () => setIsEditing(true);
  const handleClose = () => setIsEditing(false);
  const handleUpdate = (newData) => setUserData(newData);

  if (loading) {
    return <Loader />; 
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container-fluid p-4 profile-container">
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 col-lg-4 user-card-container">
          <div className="user-card p-4">
            {/* <div className="user-avatar">
              <FaUserCircle size={60} /> 
            </div> */}
            <div className="card-body-profile text-center">
              <h5 className="user-name">{userData.name}</h5>
              <p className="user-email">
                <i className="bi bi-envelope"></i> {userData.email}
              </p>
              <button className="btn-edit" onClick={handleEdit}>
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>

    
      {isEditing && (
        <EditProfile
          userData={userData}
          onClose={handleClose}
          onUpdate={handleUpdate}
          fetchUserProfile={fetchUserProfile}
        />
      )}
    </div>
  );
};

export default Profile;
