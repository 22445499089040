import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "./Filters_Tickets.css"; // Import custom styles
import Loader from "../../Loader/Loader";

const Filters_Tickets = ({ setTickets, onClose }) => {
  const [showCustomDate, setShowCustomDate] = useState({
    createdDate: false,
    closedDate: false,
    resolvedDate: false,
  });

  const [dateRange, setDateRange] = useState({
    createdDate: { from: "", to: "" },
    closedDate: { from: "", to: "" },
    resolvedDate: { from: "", to: "" },
  });

  const [ticketFilters, setTicketFilters] = useState({
    closedAtOptions: [
      "Select closing date",
      "Last 24 hours",
      "Last 12 hours",
      "Last 7 days",
      "Last 30 days",
      "Custom",
    ],
    resolvedAtOptions: [
      "Select resolved date",
      "Last 24 hours",
      "Last 12 hours",
      "Last 7 days",
      "Last 30 days",
      "Custom",
    ],
    prioritiesIncludeOptions: [
      "Select priority",
      "Low",
      "Medium",
      "High",
      "Urgent",
    ],
    typesIncludeOptions: [
      "Select type",
      "Open",
      "Pending",
      "Resolved",
      "Closed",
    ],
  });

  const [selectedResolvedDate, setSelectedResolvedDate] = useState(
    "Select resolved date"
  );
  const [selectedClosedDate, setSelectedClosedDate] = useState(
    "Select closing date"
  );
  const [selectedTypeValue, setSelectedTypeValue] = useState("Select type");
  const [selectedPriorityValue, setSelectedPriorityValue] =useState("Select priority");
  const [selectedCreated, setSelectedCreated] =useState("createdDate");
  const [loading, setLoading] = useState(true);

  const [responseData, setResponseData] = useState([]);
  // Map for converting dropdown selections into query param values
  const dateMapping = {
    "Last 24 hours": "24_hours",
    "Last 12 hours": "12_hours",
    "Last 7 days": "7_days",
    "Last 30 days": "30_days",
    Custom: "custom",
  };

  // Utility function to format date as yyyy-mm-dd
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (event, type) => {
    const isCustomSelected = event.target.value === "Custom";
    setShowCustomDate((prev) => ({ ...prev, [type]: isCustomSelected }));
    if (!isCustomSelected) {
      setDateRange((prev) => ({ ...prev, [type]: { from: "", to: "" } }));
    }
  };

  const handleCustomDateChange = (type, field, value) => {
    setDateRange((prev) => ({
      ...prev,
      [type]: { ...prev[type], [field]: value },
    }));
  };

  const handleApplyFilters = () => {
    setLoading(true); 
    const params = new URLSearchParams();

    // Created Date filter (if selected as custom date)
    if (dateRange.createdDate.from) {
      params.append("created_at", dateRange.createdDate.from);
    }
    sessionStorage.setItem("type", dateRange.createdDate.from);
   
    // Resolved Date filter
    if (showCustomDate.resolvedDate) {
      if (dateRange.resolvedDate.from) {
        params.append("resolved_at", "custom");
        params.append("from_date", dateRange.resolvedDate.from);
      }
      if (dateRange.resolvedDate.to) {
        params.append("to_date", dateRange.resolvedDate.to);
      }
    } else {
      const resolvedValue = dateMapping[selectedResolvedDate] || "";
      if (resolvedValue) params.append("resolved_at", resolvedValue);
    }
    sessionStorage.setItem("resolved date", setSelectedResolvedDate);
    
    // Closed Date filter
    if (showCustomDate.closedDate) {
      if (dateRange.closedDate.from) {
        params.append("closed_at", "custom");
        params.append("closed_from_date", dateRange.closedDate.from);
      }
      if (dateRange.closedDate.to) {
        params.append("closed_to_date", dateRange.closedDate.to);
      }
    } else {
      const closedValue = dateMapping[selectedClosedDate] || "";
      if (closedValue) params.append("closed_at", closedValue);
    }
    sessionStorage.setItem("closing date", setSelectedClosedDate);
   
    // Type filter
    console.log("Type", selectedTypeValue);
    if (selectedTypeValue !== "Select type" && selectedTypeValue !== null) {
      params.append("ticket_status", selectedTypeValue.toLowerCase());
    }
    sessionStorage.setItem("type", selectedTypeValue);

    // Priority filter
    console.log("selectedPriorityValue", selectedPriorityValue);
    if (
      selectedPriorityValue !== "Select priority" &&
      selectedPriorityValue !== null
    ) {
      params.append("ticket_priority", selectedPriorityValue.toLowerCase());
    }
    sessionStorage.setItem("priority", selectedPriorityValue);

    // Example of how to use the filter params
    const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
    fetch(
      `https://ticketsystem.techfluxsolutions.com/agents/list_tickets_filters?${params.toString()}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Filtered Data:", data.tickets);

        const agentTickets = data.tickets.map((ticket) => ({
          id: ticket.ticket_id,
          title: ticket.ticket_subject,
          dueStatus: ticket.ticket_status,
          priority: ticket.ticket_priority,
          creator: ticket.ticket_email,
          created: ticket.created_at,
          updated: ticket.updated_at,
          message: ticket.ticket_message,
          agentNameByAPI: ticket.agent_name,
        }));
        // setResponseData(data.tickets);
        setTickets(agentTickets);

        // sessionStorage.setItem("filteredData", JSON.stringify(data?.tickets));
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        console.error("Error applying filters:", error);
        onClose(); // Optionally close the modal even on failure
      });
  };

  useEffect(() => {
    setSelectedTypeValue(sessionStorage.getItem("type"));
    setSelectedPriorityValue(sessionStorage.getItem("priority"));
  }, []);

  return (
    <>
        {/* <Loader/>  */}
    <Modal
      show
      onHide={onClose}
      centered
      dialogClassName="filters-tickets-modal"
      className="mt-4 filters-tickets-modal"
    >
   
      <Modal.Header closeButton>
        <Modal.Title>Filter Tickets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Created Date */}
          <Form.Group controlId="createdDate">
            <Form.Label>Created Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Created Date"
              value={formatDate(dateRange.createdDate.from)}
              onChange={(e) =>
                handleCustomDateChange("createdDate", "from", e.target.value)
              }
            />
          </Form.Group>

          {/* Closed Date */}
          <Form.Group controlId="closedDate">
            <Form.Label>Closed Date</Form.Label>
            <Form.Select
              value={selectedClosedDate}
              onChange={(e) => {
                setSelectedClosedDate(e.target.value);
                handleDateChange(e, "closedDate");
              }}
            >
              {ticketFilters.closedAtOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
            {showCustomDate.closedDate && (
              <Row>
                <Col>
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="date"
                    value={formatDate(dateRange.closedDate.from)}
                    onChange={(e) =>
                      handleCustomDateChange(
                        "closedDate",
                        "from",
                        e.target.value
                      )
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    type="date"
                    value={formatDate(dateRange.closedDate.to)}
                    onChange={(e) =>
                      handleCustomDateChange("closedDate", "to", e.target.value)
                    }
                  />
                </Col>
              </Row>
            )}
          </Form.Group>

          {/* Resolved Date */}
          <Form.Group controlId="resolvedDate">
            <Form.Label>Resolved Date</Form.Label>
            <Form.Select
              value={selectedResolvedDate}
              onChange={(e) => {
                setSelectedResolvedDate(e.target.value);
                handleDateChange(e, "resolvedDate");
              }}
            >
              {ticketFilters.resolvedAtOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
            {showCustomDate.resolvedDate && (
              <Row>
                <Col>
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="date"
                    value={formatDate(dateRange.resolvedDate.from)}
                    onChange={(e) =>
                      handleCustomDateChange(
                        "resolvedDate",
                        "from",
                        e.target.value
                      )
                    }
                  />
                </Col>
                <Col>
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    type="date"
                    value={formatDate(dateRange.resolvedDate.to)}
                    onChange={(e) =>
                      handleCustomDateChange(
                        "resolvedDate",
                        "to",
                        e.target.value
                      )
                    }
                  />
                </Col>
              </Row>
            )}
          </Form.Group>

          {/* Type */}
          <Form.Group controlId="ticketType">
            <Form.Label>Type</Form.Label>
            <Form.Select
              value={selectedTypeValue}
              onChange={(e) => {
                console.log(e.target.value);
                setSelectedTypeValue(e.target.value);
              }}
            >
              {ticketFilters.typesIncludeOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* Priority */}
          <Form.Group controlId="priority">
            <Form.Label>Priority</Form.Label>
            <Form.Select
              value={selectedPriorityValue}
              onChange={(e) => setSelectedPriorityValue(e.target.value)}
            >
              {ticketFilters.prioritiesIncludeOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-cancel" onClick={onClose}>
          Cancel
        </Button>
        <Button className="btn-update" onClick={handleApplyFilters}>
          Apply Filters
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default Filters_Tickets;
