



import React from 'react';

import { Modal, Button } from 'react-bootstrap';

const DeleteRoleModal = ({ show, onHide, role, onDelete , fetchRoles}) => {

 const handleDelete = () => {

  onDelete(role.id);

 };

 return (

  <Modal show={show} onHide={onHide} centered>

   <Modal.Header closeButton>

    <Modal.Title>Delete Role</Modal.Title>

   </Modal.Header>

   <Modal.Body>

    Are you sure you want to delete the role "{role?.role_name}"?

   </Modal.Body>

   <Modal.Footer>

    <Button className="btn-cancel" onClick={onHide}>

     Cancel

    </Button>

    <Button className="btn-update" onClick={handleDelete}>

     Yes

    </Button>

   </Modal.Footer>

  </Modal>

 );

};

export default DeleteRoleModal;





