
import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import Loader from "./../../../Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreateDepartmentModal.css";

const CreateDepartmentModal = ({ show, onHide, onCreate ,fetchDepartments}) => {
  const [name, setName] = useState("");
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [businessHours, setBusinessHours] = useState("General Working Hours");
  const [loading, setLoading] = useState(false);

  //// Anurag's Code
  useEffect(() => {
    // Clear state when the modal opens or closes
    if (!show) {
      setName("");
      setSelectedAgents([]);
      setBusinessHours("General Working Hours");
    }
  }, [show]);

  // const handleSubmit = async () => {
  //   const agents = selectedAgents.map((agent) => agent.value);
  //   setLoading(true);

  // const handleSubmit = async () => {
  //   if (!name.trim()) {
  //     // Show an error toast if the name field is empty
  //     toast.error("Department name is required.");
  //     return;
  //   }

  //   const agents = selectedAgents.map((agent) => agent.value);
  //   setLoading(true);


  //   try {
  //     // Retrieve TicketSystemSuperAdminToken from localStorage
  //     const token = sessionStorage.getItem("TicketSystemSuperAdminToken");

  //     const response = await axios.post(
  //       "https://ticketsystem.techfluxsolutions.com/add_department_new",
  //       {
  //         department_name: name,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     onCreate({ id: Date.now(), name, agents, businessHours });
  //     setName("");
  //     setSelectedAgents([]);
  //     setBusinessHours("General Working Hours");
  //     onHide();

  //     toast.success("Department created successfully!");
  //     fetchDepartments();
  //   } catch (error) {
  //     toast.error("Failed to create department. Please try again.");
  //     console.error("Error creating department:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async () => {
    if (!name.trim()) {
      // Show an error toast if the name field is empty
      toast.error("Department name is required.");
      return;
    }
  
    const agents = selectedAgents.map((agent) => agent.value);
    setLoading(true);
  
    try {
      // Retrieve TicketSystemSuperAdminToken from sessionStorage
      const token = sessionStorage.getItem("TicketSystemSuperAdminToken");
  
      const response = await axios.post(
        "https://ticketsystem.techfluxsolutions.com/add_department_new",
        {
          department_name: name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.response === false) {
        // Display the error message from the response
        toast.error(response.data.error_msg || "Failed to create department.");
        return;
      }
  
      // Call onCreate with the new department details
      onCreate({ id: Date.now(), name, agents, businessHours });
      setName("");
      setSelectedAgents([]);
      setBusinessHours("General Working Hours");
      onHide();
  
      toast.success("Department created successfully!");
      fetchDepartments();
    } catch (error) {
      toast.error("Failed to create department. Please try again.");
      console.error("Error creating department:", error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      {loading && <Loader />}
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Department Name<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                className="form-control-department"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents default form submission
                    handleSubmit();
                  }
                }}
                placeholder="Enter Department Name"
                required
              />
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={onHide}>
            Cancel
          </Button>
          <Button className="btn-update" onClick={handleSubmit}
           disabled={loading}>
            {loading ? "Creating..." : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateDepartmentModal;
